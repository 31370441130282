import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import { Stack, StepContent, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles";

import { VisaBrief } from "./VisaBrief";
import { VisaEntry } from "../VisaEntry";

const steps = ["Visa", "Molsa", "Embassy", "Flight"];

export const ProcessSteps = () => {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    for (let i = 0; i <= activeStep; i++) newCompleted[i] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  //   setCompleted({});
  // };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        orientation={isSmallScreen ? "vertical" : "vertical"}
      >
        <Step key="0" completed={completed[0]} sx={{ minWidth: "300px" }}>
          <StepButton color="inherit" onClick={handleStep(0)}>
            Visa
          </StepButton>
          <StepContent>
            <VisaBrief />
            <Stack spacing={1} direction="row" justifyContent="flex-end">
              {/* <Button
                onClick={handleComplete}
                color="secondary"
                variant="contained"
              >
                Give Visa
              </Button> */}
              {/* <Button
                onClick={handleComplete}
                color="secondary"
                variant="contained"
              >
                Release Visa
              </Button> */}
            </Stack>
          </StepContent>
        </Step>
        <Step key="1" completed={completed[1]}>
          <StepButton color="inherit" onClick={handleStep(1)}>
            Molsa
          </StepButton>
          <StepContent>
            <Button
              onClick={handleComplete}
              color="secondary"
              variant="contained"
            >
              Completed
            </Button>
          </StepContent>
        </Step>
        <Step key="2" completed={completed[2]}>
          <StepButton color="inherit" onClick={handleStep(2)}>
            Embassy
          </StepButton>
          <StepContent>
            <Button
              onClick={handleComplete}
              color="secondary"
              variant="contained"
            >
              Completed
            </Button>
          </StepContent>
        </Step>
        <Step key="3" completed={completed[3]}>
          <StepButton color="inherit" onClick={handleStep(3)}>
            Flight
          </StepButton>
          <StepContent>
            <Button
              onClick={handleComplete}
              color="secondary"
              variant="contained"
            >
              Completed
            </Button>
          </StepContent>
        </Step>
      </Stepper>
      {/* <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography
                    variant="caption"
                    sx={{ display: "inline-block" }}
                  >
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete} color="secondary"
                  variant="contained">
                    {completedSteps() === totalSteps() - 1
                      ? "Finish"
                      : "Complete Step"}
                  </Button>
                ))}
            </Box>
          </React.Fragment>
        )}
      </div> */}
    </Box>
  );
};
