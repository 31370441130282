import { Delete, Add, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  Button,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { FormikProps } from "formik";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikTextField } from "../../../components/Layout/FormikTextField";
import { StyledAccordionSummary } from "../../../styles/componentStyled";

import { StyledTableRow, StyledTableCell } from "../../../styles/tableStyles";
import {
  CountriesList,
  ProfessionalTypes,
  ExperienceYears,
  Employee,
  LanguageExperience,
  WorkExperience,
  Skills,
} from "../types/processTypes";

interface ExperienceTabProps {
  formikData: FormikProps<Employee>;
}
export const Experience = ({ formikData }: ExperienceTabProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [country, setCountry] = useState(CountriesList.ethiopia);
  const [profession, setProfession] = useState(ProfessionalTypes.housemaid);
  const [years, setYears] = useState(ExperienceYears.two);
  const onChangeC = (value: ChangeEvent<HTMLInputElement>) => {
    setCountry(value.target.value as CountriesList);
  };
  const onChangeP = (value: ChangeEvent<HTMLInputElement>) => {
    setProfession(value.target.value as ProfessionalTypes);
  };
  const onChangeY = (value: ChangeEvent<HTMLInputElement>) => {
    setYears(value.target.value as ExperienceYears);
  };

  const addExperience = () => {
    if (formikData.values.workExperience)
      formikData.setFieldValue("workExperience", [
        ...(formikData.values.workExperience as [WorkExperience]),
        { country, profession, years },
      ]);
    else
      formikData.setFieldValue("workExperience", [
        { country, profession, years },
      ]);
  };
  const removeExperience = (id: string) => {
    formikData.setFieldValue(
      "workExperience",
      formikData.values.workExperience?.filter((w) => w._id !== id)
    );
  };
  return (
    <Stack spacing={1}>
      <Grid container spacing={1}>
        <Grid item md={3} xs={12}>
          {/* <Field
            name="education.arabicLanguage"
            component={CustomizedSelectForFormik}
          >
            {Object.keys(LanguageExperience).map((option) => (
              <MenuItem key={option} value={option as string}>
                {option}
              </MenuItem>
            ))}
          </Field> */}
          <FormikTextField
            formikKey="education.arabicLanguage"
            select
            label={t("arabic")}
          >
            {Object.keys(LanguageExperience).map((option) => (
              <MenuItem key={option} value={option as string}>
                {t((option as string).toLowerCase())}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
        <Grid item md={3} xs={12}>
          <FormikTextField
            formikKey="education.englishLanguage"
            select
            label={t("english")}
          >
            {Object.keys(LanguageExperience).map((option) => (
              <MenuItem key={option} value={option as string}>
                {t((option as string).toLowerCase())}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
        {/* multiline rows={4} */}
        <Grid item xs={12}>
          <FormikTextField
            formikKey="education.skills"
            select
            label="Skills"
            SelectProps={{
              multiple: true,
              displayEmpty: true,
              defaultValue: [],
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },

                variant: "menu",
              },
            }}
          >
            {Object.keys(Skills).map((option) => (
              <MenuItem
                key={option}
                value={option as string}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: theme.palette.action.selected,
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
              >
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
      </Grid>

      <Divider variant="fullWidth" textAlign="left" sx={{ mt: 1 }}>
        Experiences
      </Divider>

      <>
        <Grid container justifyContent="flex-start" sx={{ mt: 0 }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>{t("country")}</StyledTableCell>
                  <StyledTableCell>Profession</StyledTableCell>
                  <StyledTableCell>{t("forYears")}</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {formikData.values.workExperience &&
                  formikData.values.workExperience.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{row.country}</StyledTableCell>
                      <StyledTableCell>{row.profession}</StyledTableCell>
                      <StyledTableCell>
                        {t((row.years as string).toLowerCase())}
                      </StyledTableCell>

                      <StyledTableCell>
                        <IconButton
                          color="secondary"
                          onClick={() => removeExperience(row._id as string)}
                          size="large"
                        >
                          <Delete />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Accordion>
          <StyledAccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6" component="div">
              Add Experience
            </Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item md={3} xs={12}>
                <TextField
                  id="experienceCountry"
                  select
                  defaultValue="Ethiopia"
                  label={t("country")}
                  onChange={onChangeC}
                  value={country}
                  fullWidth
                >
                  {Object.keys(CountriesList).map((option) => (
                    <MenuItem key={option} value={option as string}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item md={3} xs={12}>
                <TextField
                  id="experienceProfession"
                  select
                  defaultValue="Housemaid"
                  label="Profession"
                  onChange={onChangeP}
                  value={profession}
                  fullWidth
                >
                  {Object.keys(ProfessionalTypes).map((option) => (
                    <MenuItem key={option} value={option as string}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item md={3} xs={12}>
                <TextField
                  id="experienceYears"
                  select
                  defaultValue="Two"
                  label={t("forYears")}
                  onChange={onChangeY}
                  value={years}
                  fullWidth
                >
                  {Object.keys(ExperienceYears).map((option) => (
                    <MenuItem key={option} value={option as string}>
                      {t(option.toLowerCase())}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={3} xs={12}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={addExperience}
                  size="large"
                  sx={{ mt: 1 }}
                >
                  <Add /> Add
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </>
    </Stack>
  );
};
