import {
  BarChart as BarChartIcon,
  Lock as LockIcon,
  ExitToApp as ExitToAppIcon,
  AccountCircle as UserIcon,
  People as UsersIcon,
  Description,
  CorporateFareSharp,
  Security,
  QuestionAnswer,
  Person2,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { RoleTypes } from "../../features/auth/types/roleTypes";
import { NavItemChildProps, NavItemProps } from "./types";

export const SideBarItems = (userRoles: string[]) => {
  const { t } = useTranslation();
  const { t: ta } = useTranslation("auth");
  let menuItems: NavItemProps[] = [
    {
      href: "/app/dashboard",
      icon: <BarChartIcon />,
      title: `${t("dashboard")}`,
      role: RoleTypes.ViewDashboard,
    },

    {
      href: "",
      icon: <Person2 />,
      title: `${t("cv")}`,
      role: RoleTypes.ViewDashboard,
      children: [
        {
          href: "/app/cvs",
          icon: <UsersIcon />,
          title: `${t("brief")}`,
          role: RoleTypes.ViewDashboard,
        },
        {
          href: "/app/cvd",
          icon: <UsersIcon />,
          title: `${t("detail")}`,
          role: RoleTypes.ViewDashboard,
        },
      ],
    },
    {
      href: "",
      icon: <UsersIcon />,
      title: `${t("employees")}`,
      role: RoleTypes.ViewDashboard,
      children: [
        {
          href: "/app/employees",
          icon: <UsersIcon />,
          title: `${t("list")}`,
          role: RoleTypes.ViewDashboard,
        },
        {
          href: "/app/employee/0",
          icon: <UsersIcon />,
          title: `${t("addNew")}`,
          role: RoleTypes.ViewDashboard,
        },
      ],
    },
    {
      href: "",
      icon: <Description />,
      title: `${t("visas")}`,
      role: RoleTypes.ViewDashboard,
      children: [
        {
          href: "/app/visas",
          icon: <UsersIcon />,
          title: `${t("list")}`,
          role: RoleTypes.ViewDashboard,
        },
        {
          href: "/app/visa/0",
          icon: <UsersIcon />,
          title: `${t("addNew")}`,
          role: RoleTypes.ViewDashboard,
        },
      ],
    },
    {
      href: "",
      icon: <QuestionAnswer />,
      title: `${t("complaints")}`,
      role: RoleTypes.ViewDashboard,
      children: [
        {
          href: "/app/visas",
          icon: <UsersIcon />,
          title: `${t("list")}`,
          role: RoleTypes.ViewDashboard,
        },
        {
          href: "/app/visa/0",
          icon: <UsersIcon />,
          title: `${t("addNew")}`,
          role: RoleTypes.ViewDashboard,
        },
      ],
    },

    {
      href: "",
      icon: <Security />,
      title: `${ta("admin")}`,
      role: "Admin",
      children: [
        {
          href: "/app/cp",
          icon: <CorporateFareSharp />,
          title: `${ta("companyProfile")}`,
          role: RoleTypes.ViewDashboard,
        },
        {
          href: "/app/users",
          icon: <UsersIcon />,
          title: `${ta("users")}`,
          role: RoleTypes.ViewDashboard,
          clickedText: "",
        },

        {
          href: "/app/c",
          icon: <CorporateFareSharp />,
          title: `${ta("companies")}`,
          role: RoleTypes.ViewDashboard,
          clickedText: "",
        },
      ],
    },
  ];

  const checkUserPrivilege = (item: NavItemProps) => {
    if (
      userRoles.some((userRole) => userRole === item.role) ||
      userRoles.some(
        (userRole) => userRole === item.role.replace("View", "Add")
      ) ||
      userRoles.some(
        (userRole) => userRole === item.role.replace("View", "Manage")
      )
    )
      return true;
    return false;
  };
  let privilegedMenuItems: NavItemProps[] = [];

  menuItems.forEach((menuItem) => {
    if (menuItem.children) {
      let nestedItem: NavItemChildProps[] = [];
      menuItem.children.forEach((child) => {
        if (checkUserPrivilege(child)) nestedItem.push(child);
      });
      if (nestedItem.length > 0) {
        privilegedMenuItems.push({
          ...menuItem,
          children: nestedItem as NavItemChildProps[],
        });
      }
    } else {
      if (checkUserPrivilege(menuItem)) privilegedMenuItems.push(menuItem);
    }
  });

  privilegedMenuItems = privilegedMenuItems.concat([
    {
      href: "/app/profile",
      icon: <UserIcon />,
      title: `${ta("myProfile")}`,
      role: RoleTypes.ViewDashboard,
    },
    {
      href: "",
      icon: <LockIcon />,
      title: `${ta("changePassword")}`,
      clickedText: "changePassword",
      role: RoleTypes.ViewDashboard,
    },
    {
      href: "",
      icon: <ExitToAppIcon />,
      title: `${ta("signOut")}`,
      clickedText: "logout",
      role: RoleTypes.ViewDashboard,
    },
  ]);
  return privilegedMenuItems;

  // {
  //     href: "/app/settings",
  //     icon: <SettingsIcon />,
  //     title: "Settings",
  //   },
};
