import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  fetchUsers,
  selectSetups,
  createUser,
  removeUser,
  getUser,
} from "../setupSlices";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";

import {
  changePageTitle,
  selectPreference,
} from "../../preferences/preferencesSlice";
//import Avatar from "@mui/material/Avatar";
import {
  IconButton,
  Stack,
  Button,
  Typography,
  Tooltip,
  Box,
} from "@mui/material";
import { Add, Edit, Save, Delete } from "@mui/icons-material";

import Accordion from "@mui/material/Accordion";
import { StyledAccordionSummary } from "../../../styles/componentStyled";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Form, Formik, FormikProps } from "formik";
import { FormikTextField } from "../../../components/Layout/FormikTextField";
import { StyledTableCell, StyledTableRow } from "../../../styles/tableStyles";
import TableSkeleton from "../../../components/Layout/TableSkeleton";
import { createUserSchema } from "../validation";
import { CreateUser } from "../../auth/types/authType";
import Paging from "../../../components/Layout/Paging";
import { selectAuth } from "../../auth/authSlice";
import { User } from "./User";
//import { selectAuth } from "../../auth/authSlice";

const defaultUser: CreateUser = {
  email: "",
  name: "",
};
interface CompanyUserProps {
  companyId?: string | undefined;
}
export const Users = ({ companyId }: CompanyUserProps) => {
  const [expanded, setExpanded] = useState(false);
  const [editExpanded, setEditExpanded] = useState(false);
  const [newUser, setNewUser] = useState(defaultUser);

  const dispatch = useAppDispatch();
  const {
    usersWithCount: { users, totalCount },
    selectedUser,
    loading,
  } = useAppSelector(selectSetups);
  const { user } = useAppSelector(selectAuth);
  const { searchText } = useAppSelector(selectPreference);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    dispatch(changePageTitle("Users List"));

    const skipRows = currentPage * rowsPerPage;
    let compId = user && user.company ? user.company?._id : "";
    if (companyId) compId = companyId;

    if (user)
      dispatch(
        fetchUsers({
          companyId: compId,
          searchText,
          skip: skipRows,
          take: rowsPerPage,
        })
      );
  }, [dispatch, searchText, currentPage, rowsPerPage, user, companyId]); //

  useEffect(() => {
    setTotal(totalCount as number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount]);

  const ToggleAccordion = () => {
    setExpanded(!expanded);
  };
  const ToggleEditAccordion = () => {
    setEditExpanded(!editExpanded);
  };
  const DeleteUser = (id: string) => {
    dispatch(removeUser(id));
  };
  const EditUser = (id: string) => {
    dispatch(getUser(id));
    setEditExpanded(true);
  };

  const ResetFields = () => {
    setNewUser(defaultUser);
    setExpanded(true);
  };

  // const RefreshList = () => {
  //   const skipRows = currentPage * rowsPerPage;
  //   let compId = user && user.company ? user.company?._id : "";
  //   if (companyId) compId = companyId;

  //   dispatch(
  //     fetchUsers({
  //       companyId: compId,
  //       refreshList: "refresh",
  //       searchText,
  //       skip: skipRows,
  //       take: rowsPerPage,
  //     })
  //   );
  // };
  return (
    <>
      <Helmet>
        <title>Users | Enjaz+</title>
      </Helmet>
      <Box
        sx={{
          m: { xs: 0, md: 1 },
          px: { xs: 0, md: 2 },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          justifyItems="center"
        >
          <Tooltip title="Add New User">
            <Button color="secondary" variant="contained" onClick={ResetFields}>
              <Add />
            </Button>
          </Tooltip>
        </Stack>

        {user && (user?.company || companyId) && (
          <Accordion sx={{ mt: 1 }} expanded={expanded}>
            <StyledAccordionSummary
              onClick={ToggleAccordion}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Add New User</Typography>
            </StyledAccordionSummary>
            <AccordionDetails>
              <Formik
                enableReinitialize={true}
                initialValues={newUser as CreateUser}
                validationSchema={createUserSchema}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(false);
                  if (user && user.company) {
                    values = { ...values, companyId: user.company._id };
                  } else if (companyId) {
                    values = { ...values, companyId: companyId };
                  }
                  dispatch(createUser(values));
                }}
              >
                {(props: FormikProps<CreateUser>) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormikTextField formikKey="name" label="Name" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikTextField formikKey="email" label="Email" />
                      </Grid>
                    </Grid>

                    <br />
                    {/* {success && <Toast severity="success">{success.message}</Toast>}
              {error && <Toast severity="error">{error.message}</Toast>} */}
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={!props.isValid}
                    >
                      <Save />
                      Add
                    </Button>
                  </Form>
                )}
              </Formik>
            </AccordionDetails>
          </Accordion>
        )}
        {selectedUser && selectedUser._id && selectedUser._id?.length > 0 && (
          <Accordion sx={{ mt: 1 }} expanded={editExpanded}>
            <StyledAccordionSummary
              onClick={ToggleEditAccordion}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Edit User Profile</Typography>
            </StyledAccordionSummary>
            <AccordionDetails>
              <User userId={selectedUser._id as string} />
            </AccordionDetails>
          </Accordion>
        )}
        <Grid container justifyContent="flex-start" sx={{ mt: 1 }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>S.No</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {loading === "pending" ? (
                  <TableSkeleton numRows={5} numColumns={3} />
                ) : (
                  users.map((row, index) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell>{row.email}</StyledTableCell>

                      <StyledTableCell>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <IconButton
                            color="primary"
                            onClick={() => EditUser(row._id as string)}
                            size="large"
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            color="secondary"
                            onClick={() => DeleteUser(row._id as string)}
                            size="large"
                          >
                            <Delete />
                          </IconButton>
                        </Stack>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Stack spacing={1}>
          <Paging
            total={total}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            setRowsPerPage={setRowsPerPage}
            setCurrentPage={setCurrentPage}
          />
          <Typography variant="h6" color="textPrimary" component="div">
            Number of Users: {total}
          </Typography>
        </Stack>
      </Box>
    </>
  );
};
