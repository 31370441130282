import { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Divider, Grid, useTheme } from "@mui/material";
import { AddressDetail } from "./AddressDetail";
import { ContactPerson } from "./ContactPerson";
import { Experience } from "./Experience";
import { FormikProps } from "formik";
import { Employee } from "../types/processTypes";
import { useTranslation } from "react-i18next";
import { EmployeeMore } from "./EmployeeMore";
import { StyledTab } from "../../../styles/componentStyled";
import { ProcessSteps } from "./ProcessSteps";

interface EmployeeTabProps {
  formikData: FormikProps<Employee>;
}
// const SubEmployeeTabs = ({ formikData }: EmployeeTabProps) => {
//   const theme = useTheme();
//   const { t } = useTranslation();
//   const [tabValue, setValue] = useState("0");

//   const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
//     setValue(newValue);
//   };
//   return (
//     <div>
//       <TabContext value={tabValue}>
//         <Box
//           sx={{
//             my: 1,
//             backgroundColor: theme.palette.primary.main,
//             borderRadius: "4px",
//           }}
//         >
//           <TabList
//             onChange={handleTabChange}
//             variant="scrollable"
//             scrollButtons
//             allowScrollButtonsMobile
//             aria-label="lab API tabs example"
//             TabScrollButtonProps={{ color: "white" }}
//           >
//             <StyledTab
//               label="Address & Contact Person"
//               value="0"
//               wrapped
//               sx={{ width: 150 }}
//             />

//             <StyledTab
//               label={"Skills & " + t("workExperience")}
//               value="1"
//               sx={{ width: 150 }}
//               wrapped
//             />
//             <StyledTab label="CV" value="2" sx={{ width: 150 }} />
//           </TabList>
//         </Box>

//         <TabPanel sx={{ py: 0, px: 1 }} value="0">
//           <Grid container spacing={1} xs={12}>
//             <Grid item md={6} xs={12}>
//               <Accordion expanded>
//                 <StyledAccordionSummary
//                   expandIcon={<ExpandMore />}
//                   aria-controls="panel1a-content"
//                   id="panel1a-header"
//                 >
//                   <Typography variant="h5" component="div">
//                     Employee Address
//                   </Typography>
//                 </StyledAccordionSummary>
//                 <AccordionDetails>
//                   <AddressDetail />
//                 </AccordionDetails>
//               </Accordion>
//             </Grid>

//             <Grid item md={6} xs={12}>
//               <Accordion expanded>
//                 <StyledAccordionSummary
//                   expandIcon={<ExpandMore />}
//                   aria-controls="panel1a-content"
//                   id="panel1a-header"
//                 >
//                   <Typography variant="h5" component="div">
//                     Contact Person
//                   </Typography>
//                 </StyledAccordionSummary>
//                 <AccordionDetails>
//                   <ContactPerson />
//                 </AccordionDetails>
//               </Accordion>
//             </Grid>
//           </Grid>
//         </TabPanel>
//         <TabPanel sx={{ py: 0, px: 1 }} value="1">
//           <Experience formikData={formikData} />
//         </TabPanel>
//         <TabPanel sx={{ py: 0, px: 1 }} value="2">
//           <EmployeeMore />
//         </TabPanel>
//       </TabContext>
//     </div>
//   );
// };

export const EmployeeTabs = ({ formikData }: EmployeeTabProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [tabValue, setValue] = useState("0");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div>
      <TabContext value={tabValue}>
        <Box
          sx={{
            my: 1,
            backgroundColor: theme.palette.primary.main,
            borderRadius: "4px",
          }}
        >
          <TabList
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="lab API tabs example"
            TabScrollButtonProps={{ color: "white" }}
          >
            {/* <StyledTab icon={<FileCopy />} label="Visa Info" value="0" /> */}

            <StyledTab label="Processes" value="0" />
            <StyledTab
              label="Address & Contact Person"
              value="1"
              wrapped
              sx={{ width: 150 }}
            />

            <StyledTab
              label={"Skills & " + t("workExperience")}
              value="2"
              sx={{ width: 150 }}
              wrapped
            />
            <StyledTab label="CV" value="3" sx={{ width: 150 }} />
            {/* <StyledTab icon={<MoreHoriz />} label="More Data" value="1" /> */}
          </TabList>
        </Box>
        {/* <TabPanel sx={{ py: 0, px: 1 }} value="0">
          <VisaBrief />
        </TabPanel> */}
        <TabPanel sx={{ py: 0, px: 1 }} value="0">
          <ProcessSteps />
        </TabPanel>

        <TabPanel sx={{ py: 0, px: 1 }} value="1">
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <Divider variant="fullWidth" textAlign="left" sx={{ mt: 1 }}>
                Employee Address
              </Divider>
              <AddressDetail />
            </Grid>
            <Grid
              item
              md={1}
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Divider orientation="vertical"></Divider>
            </Grid>
            <Grid item md={5} xs={12}>
              <Divider variant="fullWidth" textAlign="left" sx={{ mt: 1 }}>
                Contact Person
              </Divider>
              <ContactPerson />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel sx={{ py: 0, px: 1 }} value="2">
          <Experience formikData={formikData} />
        </TabPanel>
        <TabPanel sx={{ py: 0, px: 1 }} value="3">
          <EmployeeMore formikData={formikData} />
        </TabPanel>
        {/* <TabPanel sx={{ py: 0, px: 1 }} value="1">
          <SubEmployeeTabs formikData={formikData} />
        </TabPanel> */}
      </TabContext>
    </div>
  );
};
