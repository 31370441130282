// import * as React from "react";
// import Box from "@mui/material/Box";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";

// export default function CountrySelect() {
//   return (

//   );
// }

export interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countries: readonly CountryType[] = [
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "SA", label: "SaudiArabia", phone: "966" },
  {
    code: "AE",
    label: "UAE",
    phone: "971",
  },
  { code: "JO", label: "Jordan", phone: "962" },
  { code: "QA", label: "Qatar", phone: "974" },

  { code: "BH", label: "Bahrain", phone: "973" },

  { code: "KW", label: "Kuwait", phone: "965" },

  { code: "LB", label: "Lebanon", phone: "961" },

  { code: "OM", label: "Oman", phone: "968" },

  { code: "YE", label: "Yemen", phone: "967" },
];
