import SupportChat from "./SupportChat";
const Footer = () => {
  return (
    <footer>
      <SupportChat />
      {/*<Box
        mt={1}
        px={2}
        width={"100%"}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <StyledLink
          aria-label="Chat on WhatsApp"
          href="https://wa.me/251911850908"
          target={"_blank"}
        >
          {" "}
          <img
            alt="Chat on WhatsApp"
            src="/Digital_Glyph_Dark_Green.svg"
            width="120px"
            height="36px"
          />
        </StyledLink>{" "}
         <StyledLink
          href={"https://www.facebook.com/pinnasofts"}
          target={"_blank"}
        >
          <i className="fa fa-whatsapp"></i>
        </StyledLink>
        <StyledLink href={"https://twitter.com/pinnasofts"} target={"_blank"}>
          <i className="fa fa-twitter"></i>
        </StyledLink>
        <StyledLink href={"https://github.com/pinnasofts"} target={"_blank"}>
          <i className="fa fa-github"></i>
        </StyledLink>{" "} 
      </Box>*/}
    </footer>
  );
};

export default Footer;
