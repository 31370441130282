import { gql } from "@apollo/client";
export const GET_VISA = gql`
  fragment getVisa on Visa {
    _id
    visaNumber
    fullName
    passportNumber
    visaQty
    salary
    currency
    remarks
    firstTime
    goodLooking
    writeRead
    profession
    religion
    ageCategory
    complexion
    status
    address {
      telephone
      city
      country
    }
    owner {
      _id
      type
      displayName
      email
    }
  }
`;

export const GET_EMPLOYEE = gql`
  ${GET_VISA}
  fragment getEmployee on Employee {
    _id
    status
    passportNumber
    placeOfBirth
    placeOfIssue
    givenName
    surName
    facePhoto
    standPhoto
    passportScan
    sex
    dateOfBirth
    passportIssueDate
    passportExpiryDate
    documents
    additionalData {
      nationality
      pastNationality
      maritalStatus
      religion
      appliedProfession
      appliedCountry
      contractPeriod
      complexion
      salary
      currency
      numberOfChildren
      height
      weight
    }
    address {
      telephone
      city
      country
    }
    contactPerson {
      displayName
      kinship
      address {
        telephone
        city
        country
      }
    }
    education {
      arabicLanguage
      englishLanguage
      educationLevel
      skills
      fieldOfStudy
      yearCompleted
      educationQG
      remarks
    }
    embassyProcess {
      enjazNumber
    }
    workExperience {
      _id
      country
      profession
      years
    }
    visa {
      ...getVisa
    }
    owner {
      _id
      type
      displayName
      email
      address {
        telephone
        city
        country
      }
    }
    prospects {
      _id
      type
      displayName
      email
    }
  }
`;
