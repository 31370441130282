import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
import About from "./About";
import { Stack } from "@mui/material";

const LandingPage = () => {
  return (
    <>
      <Helmet>
        <title>Home | Enjaz+</title>
      </Helmet>
      <Stack spacing={1}>
        <Header />
        <About />
        <Footer />
      </Stack>
    </>
  );
};
export default LandingPage;
