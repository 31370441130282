import { gql } from "@apollo/client";
import { GET_EMPLOYEE, GET_VISA } from "../fragments/processes";

export const GET_ALL_EMPLOYEES = gql`
  ${GET_EMPLOYEE}
  query GetEmployees(
    $searchText: String
    $status: EmployeeStatus
    $skip: Int!
    $take: Int!
    $link: String
  ) {
    employees(
      skip: $skip
      take: $take
      searchText: $searchText
      status: $status
      link: $link
    ) {
      totalCount
      employees {
        ...getEmployee
      }
    }
  }
`;
export const GET_SHARED_EMPLOYEES = gql`
  ${GET_EMPLOYEE}
  query GetSharedEmployees($link: String) {
    sharedEmployees(link: $link) {
      totalCount
      employees {
        ...getEmployee
      }
    }
  }
`;
export const GET_ALL_SHORT_LINKS = gql`
  query GetShortLinks {
    shortLinks {
      _id
      link
      employees {
        _id
        givenName
      }
    }
  }
`;

export const GET_SELECTED_EMPLOYEE = gql`
  ${GET_EMPLOYEE}
  query GetSelectedEmployee($id: String!) {
    getEmployee(input: { _id: $id }) {
      ...getEmployee
    }
  }
`;

export const GET_ALL_VISAS = gql`
  ${GET_VISA}
  query GetVisas($searchText: String, $skip: Int!, $take: Int!) {
    visas(skip: $skip, take: $take, searchText: $searchText) {
      totalCount
      visas {
        ...getVisa
      }
    }
  }
`;

export const GET_SELECTED_VISA = gql`
  ${GET_VISA}
  query GetSelectedVisa($id: String!) {
    getVisa(input: { _id: $id }) {
      ...getVisa
    }
  }
`;

export const CHECK_STATUS_CAPTCHA = gql`
  ${GET_EMPLOYEE}
  query recaptchaCheckStatus($searchText: String, $captcha: String) {
    recaptchaCheckStatus(
      input: { searchText: $searchText, captcha: $captcha }
    ) {
      ...getEmployee
    }
  }
`;
