import { experimentalStyled } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import ListItemButton, {
  ListItemButtonProps,
} from "@mui/material/ListItemButton";
import { orange } from "@mui/material/colors";

export const GetInTouchArrow = experimentalStyled("div")(() => ({
  fontSize: "2rem",
  fontWeight: "bold",
  color: "orange",
}));
export const ImageMargin = experimentalStyled("div")(({ theme }) => ({
  marginLeft: "0",
  [theme.breakpoints.up("sm")]: {
    // zIndex: '-10',
    marginLeft: "-10rem",
  },
}));
export const StyledListItemButton = experimentalStyled(
  ListItemButton
)<ListItemButtonProps>(({ theme }) => ({
  //color: 'white',
  color: theme.palette.getContrastText(theme.palette.primary.light),
  fontSize: "1rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.5rem",
  },
  fontWeight: "bold",

  "&:hover": {
    color: "white",
  },
}));
export const StyledButton = experimentalStyled(Button)<ButtonProps>(
  ({ theme }) => ({
    //color: 'white',
    color: theme.palette.getContrastText(theme.palette.secondary.light),
    fontSize: "1rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
      color: theme.palette.getContrastText(theme.palette.primary.light),
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.5rem",
    },
    fontWeight: "bold",

    "&:hover": {
      color: "white",
    },
  })
);
export const MenuButton = experimentalStyled(Button)<ButtonProps>(
  ({ theme }) => ({
    //color: 'white',
    color: theme.palette.getContrastText(theme.palette.secondary.light),
    fontSize: "0.8rem",
    fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
      color: theme.palette.getContrastText(theme.palette.primary.light),
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
      color: theme.palette.getContrastText(theme.palette.primary.light),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4rem",
      color: theme.palette.getContrastText(theme.palette.primary.light),
    },

    "&:hover": {
      color: "orange",
    },
  })
);
export const ColorButton = experimentalStyled(Button)<ButtonProps>(
  ({ theme }) => ({
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: "white",
    borderColor: orange[600],
    "&:hover": {
      backgroundColor: "white",
      borderColor: orange[900],
    },
    borderRadius: "32px",
    // width: "200px",
    // height: "48px",
    fontSize: "1rem",
    [theme.breakpoints.up("xs")]: {
      fontSize: "1rem",
      padding: "2px 8px",
      lineHeight: "0.5",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.4rem",
      padding: "4px 12px",
      lineHeight: "1",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.8rem",
      padding: "6px 16px",
      lineHeight: "1.5",
    },
    fontWeight: "bold",
    display: "flex",
    justifyContent: "space-between",

    border: "0.25rem solid gray",
    animation: "pulse-border 1.5s linear infinite",
    animationDelay: "1.5s",
    "@keyframes pulse-border": {
      "0%": {
        transform: "scale(1)",
        opacity: 1,
      },
      "50%": {
        transform: "scale(1.2)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(1.5)",
        opacity: 0,
      },
    },
  })
);
export const LanguageButton = experimentalStyled(Button)<ButtonProps>(
  ({ theme }) => ({
    color: theme.palette.getContrastText(orange[500]),
    borderColor: orange[600],
    "&:hover": {
      borderColor: orange[900],
    },
    borderRadius: "8px",
    width: "50px",
    height: "24px",
    fontSize: "1rem",
    fontWeight: "bold",

    border: "0.55rem solid white",
  })
);
