import { Address, Company, ContactPerson } from "../../setups/types/setupTypes";

export type EmployeeArgs = {
  skip?: number;
  take?: number;
  status?: EmployeeStatus;
  searchText?: string;
  refreshList?: string;
  lastUpdated?: Date;
  link?: string;
};

export type VisaArgs = {
  skip?: number;
  take?: number;
  searchText?: string;
  refreshList?: string;
  lastUpdated?: Date;
};
export type EmployeesWithCount = {
  totalCount: number;
  employees: Employee[];
};
export type VisasWithCount = {
  totalCount: number;
  visas: Visa[];
};

export type DelResult = {
  affectedRows?: string;
};

export enum Sex {
  male = "male",
  female = "female",
}
export enum Nationalities {
  ethiopian = "ethiopian",
  sudanese = "sudanese",
  eritrean = "eritrean",
}
export enum CurrencyTypes {
  saudi_riyal = "saudi_riyal",
  qatar_riyal = "qatar_riyal",
  kuwait_dinar = "kuwait_dinar",
  emirate_dirham = "emirate_dirham",
  jordan_dinar = "jordan_dinar",
  lebanon_lire = "lebanon_lire",
  ethiopia_birr = "ethiopia_birr",
}
export type Education = {
  arabicLanguage?: LanguageExperience;
  englishLanguage?: LanguageExperience;
  educationLevel?: EducationLevel;
  fieldOfStudy?: string;
  yearCompleted?: string;
  skills?: [Skills];
  educationQG?: string;
  remarks?: string;
};

export type EmbassyProcess = {
  enjazNumber?: string;
  submissionDate?: Date;
  stamped?: boolean;
  stampedDate?: Date;
  cancelled?: boolean;
  canceledDate?: Date;
  remarks?: string;
};

export type AdditionalData = {
  nationality?: Nationalities;
  pastNationality?: Nationalities;
  maritalStatus?: MaritalStatus;
  religion?: ReligionTypes;
  appliedProfession?: ProfessionalTypes;
  appliedCountry?: CountriesList;
  contractPeriod?: ExperienceYears;
  complexion?: Complexion;
  salary?: number;
  currency?: CurrencyTypes;
  numberOfChildren?: number;
  height?: number;
  weight?: number;
  documentReceivedDate?: Date;
  notes?: string;
  discontinued?: boolean;
  discontinuedDate?: Date;
  discontinuedRemark?: string;
  afterFlightStatus?: AfterFlightStatus;
  afterFlightStatusDate?: Date;
  afterFlightStatusRemark?: string;
};

export enum LanguageExperience {
  poor = "poor",
  fair = "fair",
  good = "good",
  fluent = "fluent",
}

export enum EducationLevel {
  grade_eight_complete = "grade_eight_complete",
  secondary_complete = "secondary_complete",
  vocational_complete = "vocational_complete",
  college_complete = "college_complete",
  post_graduate = "post_graduate",
  other_education = "other_education",
}

export type Visa = {
  _id?: string;
  visaNumber: string;
  fullName: string;
  passportNumber?: string;
  visaQty?: number;
  contractNumber?: string;
  fileNumber?: string;
  bankNumber?: string;
  visaDate?: Date;
  visaDateArabic?: string;
  wekalaNumber?: string;
  wekalaDate?: string;
  profession?: ProfessionalTypes;
  salary?: number;
  currency?: CurrencyTypes;
  religion?: ReligionTypes;
  ageCategory?: AgeCategory;
  complexion?: Complexion;
  status?: VisaStatus;
  firstTime?: Boolean;
  goodLooking?: Boolean;
  writeRead?: Boolean;
  remarks?: string;
  address?: Address;
  owner?: Company;
  prospects?: [Company];
  assignedEmployees?: [string];
  createdBy?: string;
  modifiedBy?: string;
  createdAt?: Date;
  modifiedAt?: Date;
};

export enum ProfessionalTypes {
  housemaid = "housemaid",
  driver = "driver",
  baby_sitter = "baby_sitter",
  nanny = "nanny",
  cooker = "cooker",
  nurse = "nurse",
  farm_worker = "farm_worker",
  other_profession = "other_profession",
}

export enum ReligionTypes {
  muslim = "muslim",
  non_muslim = "non_muslim",
  christian = "christian",
  other_religion = "other_religion",
}

export enum AgeCategory {
  bet2125 = "bet2125",
  bet2630 = "bet2630",
  bet3135 = "bet3135",
  bet3640 = "bet3640",
  bet4145 = "bet4145",
}

export enum Complexion {
  brown = "brown",
  black = "black",
  blue = "blue",
  acceptable = "acceptable",
}

export type WorkExperience = {
  _id?: string;
  country?: CountriesList;
  profession?: ProfessionalTypes;
  years?: ExperienceYears;
};

export enum Skills {
  driving = "driving",
  baby_sitting = "baby_sitting",
  children_care = "children_care",
  tutoring_children = "tutoring_children",
  washing_ironing_clothes = "washing_ironing_clothes",
  dusting_cleaning = "dusting_cleaning",
  washing_dishes = "washing_dishes",
  cooking = "cooking",
  arabic_cooking = "arabic_cooking",
  sewing = "sewing",
  computer = "computer",
  decorating = "decorating",
  other_skills = "other_skills",
}
export enum RequiredDocuments {
  passport = "passport",
  photo = "photo",
  id_card = "id_card",
  emergency_person_photo = "emergency_person_photo",
  emergency_person_id_card = "emergency_person_id_card",
  finger_print = "finger_print",
  medical = "medical",
  grade_eight_certificate = "grade_eight_certificate",
  coc_certificate = "coc_certificate",
  insurance = "insurance",
  abroad_job_agreement = "abroad_job_agreement",
  trip_orientation = "trip_orientation",
}
export enum CountriesList {
  saudi_arabia = "saudi_arabia",
  uae = "uae",
  kuwait = "kuwait",
  qatar = "qatar",
  lebanon = "lebanon",
  bahrain = "bahrain",
  jordan = "jordan",
  yemen = "yemen",
  syria = "syria",
  ethiopia = "ethiopia",
}

export enum ExperienceYears {
  one = "one",
  two = "two",
  three = "three",
  four = "four",
  five = "five",
  six = "six",
  seven = "seven",
  eight = "eight",
  nine = "nine",
  ten = "ten",
}

export enum VisaStatus {
  new = "new",
  available = "available",
  rejected = "rejected",
  employee = "employee",
  arrived = "arrived",
  processing = "processing",
  keep_ready = "keep_ready",
  visa_ready = "visa_ready",
  on_medical = "on_medical",
  on_foreign_affairs = "on_foreign_affairs",
}
export type ShortLink = {
  _id?: string;
  link?: string;
  employeeIds?: [string];
  employees?: [Employee];
  expiredAt?: Date;
  company?: Company;
};
export type ShareLink = {
  employeeIds: [string];
  companyId: string;
};
export type Employee = {
  _id?: string;
  givenName?: string;
  surName?: string;
  passportNumber?: string;
  sex?: Sex;
  status?: EmployeeStatus;
  placeOfBirth?: string;
  placeOfIssue?: string;
  dateOfBirth?: Date;
  passportIssueDate?: Date;
  passportExpiryDate?: Date;
  documents?: [RequiredDocuments];
  additionalData?: AdditionalData;
  address?: Address;
  contactPerson?: ContactPerson;
  education?: Education;
  embassyProcess?: EmbassyProcess;
  workExperience?: [WorkExperience];
  facePhoto?: string;
  standPhoto?: string;
  passportScan?: string;
  owner?: Company;
  visa?: Visa;
  prospects?: [Company];
  createdBy?: string;
  modifiedBy?: string;
  createdAt?: Date;
  modifiedAt?: Date;
};
export enum PhotoTypes {
  Face = "Face",
  Stand = "Stand",
  Passport = "Passport",
  User = "User",
}
export enum MaritalStatus {
  single = "single",
  married = "married",
  divorced = "divorced",
  widow = "widow",
  separated = "separated",
}
enum AfterFlightStatus {
  on_good_condition = "on_good_condition",
  lost = "lost",
  returned = "returned",
}
export enum EmployeeStatus {
  new = "new",
  visa_assigned = "visa_assigned",
  on_process = "on_process",
  labour_process = "labour_process",
  embassy_process = "embassy_process",
  flight_process = "flight_process",
  flight_booked = "flight_booked",
  on_good_condition = "on_good_condition",
  discontinued = "discontinued",
  visa_canceled = "visa_canceled",
  lost = "lost",
  returned = "returned",
  with_complain = "with_complain",
}

export type ProcessState = {
  employeesWithCount: EmployeesWithCount;
  visasWithCount: VisasWithCount;
  selectedEmployee: Employee;
  sharedLinks: ShortLink[];
  selectedVisa: Visa;
  selectedShortLink: ShortLink;
  loading: "idle" | "pending";
  currentRequestId: string | undefined;
  success: any;
  error: any;
};
