import { CountryType } from "../../../components/dashboard/CountrySelect";
import { PhotoTypes } from "../../process/types/processTypes";
import {
  Company,
  ForeignAgent,
  LocalAgency,
} from "../../setups/types/setupTypes";

export type AuthUser = {
  _id?: string;
  name?: string;
  email?: string;
  password?: string;
  salt?: string;
  avatar?: string;
  bio?: string;
  status?: UserStatus;
  userType?: UserTypes;
  clientType?: ClientTypes;
  company?: Company;
  // agency?: LocalAgency;
  // agent?: ForeignAgent;
  //verifiedAt?: Date;
  token?: string;
  //expiredAt?: Date;
  roles?: Role[];
};

export type Role = {
  _id?: string;
  active?: boolean;
  createdByUserId?: number;
  modifiedByUserId?: number;
  displayName?: string;
  description?: string;
  descriptionShort?: string;
  isPrivileged?: boolean;
};

export enum UserStatus {
  Waiting = "Waiting",
  Active = "Active",
  Disabled = "Disabled",
  Blocked = "Blocked",
}

export enum UserTypes {
  Super = "Super",
  ClientAdmin = "ClientAdmin",
  ClientUser = "ClientUser",
}

export enum ClientTypes {
  LocalAgency = "LocalAgency",
  ForeignAgent = "ForeignAgent",
}

export type AuthState = {
  user: AuthUser | undefined;
  fileUploadUri: undefined | string;
  loading: "idle" | "pending";
  currentRequestId: undefined | string;
  error: RejectWithValueType | null;
  success: AuthSuccess | null;
};

export type CreateUser = {
  email: string;
  name?: string;
  agency?: LocalAgency | undefined;
  agent?: ForeignAgent | undefined;
  agencyId?: string | undefined;
  agentId?: string | undefined;
  companyId?: string | undefined;
  password?: string | undefined;
};

export type RecaptchaCheckStatus = {
  searchText?: string;
  captcha?: string;
};
export type RecaptchaRegister = {
  email?: string;
  name?: string;
  country?: CountryType;
  phone?: string;
  message?: string;
  captcha?: string;
};

export type UserCredentials = {
  email: string;
  password: string;
  captcha?: string;
};

export type User = {
  name: string;
  confirmPassword: string;
};

export type NewUser = User & UserCredentials;

export type AuthSuccess = {
  message: string;
};

export type AuthenticatedUser = {
  _id: string;
  name: string;
  email: string;
  token: string;
};

export type UpdatePassword = {
  userId: string;
  oldPassword: string;
  password: string;
  confirmPassword: string;
};

export type ForgotAuth = {
  email: string;
};

export type ResetAuth = {
  password: string;
  confirmPassword: string;
  id: string;
  token: string;
  showPassword: boolean;
};

export type VerifyAuth = {
  expires: string;
  id: string;
  token: string;
  signature: string;
};

export type VerifyResendAuth = {
  id: string;
};

export type Upload = {
  image: File;
};
export type UploadGraphQL = {
  _id: string;
  type: PhotoTypes;
  avatar: File;
};
export type UploadGraphQLResult = {
  _id: string;
  type: PhotoTypes;
  avatar: string;
};

export type RejectWithValueType = {
  code?: string;
  message: string;
  stack?: string;
  id?: string;
};
