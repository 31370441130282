import * as Yup from "yup";
import { t } from "i18next";

const passportNumRegExp = /^([Ee]{1,1})+([A-Za-z]{1,1})+([0-9]{7,7})$/;
const givenNameRegExp = /^([A-Za-z]{2,15})+ [A-Za-z]{2,15}$/;
const surNameRegExp = /^[A-Za-z]{2,15}$/;

const passportNumber = Yup.string()
  .required()
  .matches(passportNumRegExp, {
    message: t("incorrectPassportNumber"),
  });
const givenName = Yup.string().required().matches(givenNameRegExp, {
  message: "Incorrect Given Name",
});
const surName = Yup.string().required().matches(surNameRegExp, {
  message: "Incorrect Sur Name",
});
const placeOfBirth = Yup.string().required().min(2).max(15);
const placeOfIssue = Yup.string().required().min(2).max(15);
export const createEmployeeSchema = Yup.object({
  passportNumber,
  givenName,
  surName,
  placeOfBirth,
  placeOfIssue,
});

const visaNumberRegExp = /^([0-9]{8,10})$/;
const visaNumber = Yup.string().matches(visaNumberRegExp, {
  message: "Incorrect Visa Number",
  excludeEmptyString: true,
});

export const createVisaSchema = Yup.object({
  visaNumber,
});
