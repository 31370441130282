import Resizer from "react-image-file-resizer";

export const resizeFile = (file: File) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "jpeg",
      85,
      0,
      (uri) => {
        resolve(uri);
      },
      "file",
      200,
      200
    );
  });
