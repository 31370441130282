import { Typography, Button, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";

import Logo from "../process/components/Logo";
import { GridContainer } from "../../styles/errorStyled";

export default function Error() {
  const theme = useTheme();
  return (
    <GridContainer container>
      <Logo />

      <Typography
        variant="h1"
        color="primary"
        sx={{
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(10),
          textAlign: "center",
          fontSize: 96,
          fontWeight: 600,
        }}
      >
        404
      </Typography>
      <Typography
        variant="h5"
        color="primary"
        sx={{ marginBottom: theme.spacing(10), textAlign: "center" }}
      >
        Oops. Looks like the page you're looking for no longer exists
      </Typography>
      <Typography
        variant="h6"
        color="primary"
        sx={{
          marginBottom: theme.spacing(2),
          textAlign: "center",
          fontWeight: 300,
          color: theme.palette.text.primary,
        }}
      >
        But we're here to bring you back to safety
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={NavLink}
        to="/"
        size="medium"
        sx={{ textTransform: "none", fontSize: 20 }}
      >
        Back to Home
      </Button>
    </GridContainer>
  );
}
