import { gql } from "@apollo/client";
import { GET_EMPLOYEE, GET_VISA } from "../fragments/processes";

export const ADD_UPDATE_EMPLOYEE = gql`
  ${GET_EMPLOYEE}
  mutation createEmployee(
    $_id: String
    $givenName: String
    $surName: String
    $passportNumber: String
    $placeOfBirth: String
    $placeOfIssue: String
    $sex: Sex!
    $dateOfBirth: DateTime
    $passportIssueDate: DateTime
    $passportExpiryDate: DateTime
    $visa: VisaInput
    $documents: [RequiredDocuments!]
    $address: AddressInput
    $contactPerson: ContactInput
    $additionalData: AdditionalDataInput
    $education: EducationInput
    $embassyProcess: EmbassyProcessInput
    $workExperience: [WorkExperienceInput!]
    $owner: CompanyInput
    $prospects: [CompanyInput!]
  ) {
    createEmployee(
      input: {
        _id: $_id
        givenName: $givenName
        surName: $surName
        passportNumber: $passportNumber
        placeOfBirth: $placeOfBirth
        placeOfIssue: $placeOfIssue
        sex: $sex
        dateOfBirth: $dateOfBirth
        passportIssueDate: $passportIssueDate
        passportExpiryDate: $passportExpiryDate
        documents: $documents
        address: $address
        visa: $visa
        contactPerson: $contactPerson
        additionalData: $additionalData
        education: $education
        embassyProcess: $embassyProcess
        workExperience: $workExperience
        owner: $owner
        prospects: $prospects
      }
    ) {
      ...getEmployee
    }
  }
`;
export const REMOVE_EMPLOYEE = gql`
  mutation removeAnEmployee($id: String!) {
    deleteEmployee(id: $id) {
      affectedRows
    }
  }
`;
export const ADD_UPDATE_VISA = gql`
  ${GET_VISA}
  mutation createVisa(
    $_id: String
    $visaNumber: String!
    $visaQty: Int!
    $fullName: String!
    $passportNumber: String
    $salary: Int
    $remarks: String
    $firstTime: Boolean!
    $goodLooking: Boolean!
    $writeRead: Boolean!
    $currency: CurrencyTypes!
    $profession: ProfessionalTypes!
    $religion: ReligionTypes!
    $ageCategory: AgeCategory!
    $complexion: Complexion!
    $status: VisaStatus!
    $address: AddressInput
    $owner: CompanyInput
  ) {
    createVisa(
      input: {
        _id: $_id
        visaNumber: $visaNumber
        fullName: $fullName
        passportNumber: $passportNumber
        visaQty: $visaQty
        salary: $salary
        currency: $currency
        remarks: $remarks
        firstTime: $firstTime
        goodLooking: $goodLooking
        writeRead: $writeRead
        profession: $profession
        religion: $religion
        ageCategory: $ageCategory
        complexion: $complexion
        status: $status
        address: $address
        owner: $owner
      }
    ) {
      ...getVisa
    }
  }
`;

export const REMOVE_VISA = gql`
  mutation removeAVisa($id: String!) {
    deleteVisa(id: $id) {
      affectedRows
    }
  }
`;

export const GENERATE_LINK = gql`
  mutation generateLink($ids: [String!]!) {
    generateLink(input: { employeeIds: $ids }) {
      _id
      link
      employees {
        _id
        givenName
      }
    }
  }
`;
