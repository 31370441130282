import { combineReducers } from "@reduxjs/toolkit";

// Reducers
import authReducer from "../features/auth/authSlice";
import preferencesReducer from "../features/preferences/preferencesSlice";
import setupsReducer from "../features/setups/setupSlices";
import processReducer from "../features/process/processSlices";

const rootReducer = combineReducers({
  auth: authReducer,
  setups: setupsReducer,
  preferences: preferencesReducer,
  processes: processReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
