import { Grid, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormikTextField } from "../../../components/Layout/FormikTextField";
import { CountriesList } from "../types/processTypes";

export const AddressDetail = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <FormikTextField formikKey="address.telephone" label="Telephone" />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikTextField formikKey="address.city" label="City" />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikTextField
            formikKey="address.country"
            select
            label={t("country")}
          >
            {Object.keys(CountriesList).map((option) => (
              <MenuItem key={option} value={option as string}>
                {t((option as string).toLowerCase())}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
      </Grid>
    </div>
  );
};
