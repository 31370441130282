import { gql } from "@apollo/client";
import {
  GET_COMPANY,
  GET_FOREIGN_AGENT,
  GET_LOCAL_AGENCY,
} from "../fragments/setup";

export const ADD_UPDATE_COMPANY = gql`
  ${GET_COMPANY}
  mutation createCompany(
    $id: String
    $type: ClientTypes!
    $displayName: String!
    $email: String!
    $address: AddressInput
  ) {
    createCompany(
      input: {
        _id: $id
        type: $type
        displayName: $displayName
        email: $email
        address: $address
      }
    ) {
      ...getCompany
    }
  }
`;

export const LINK_COMPANIES = gql`
  ${GET_COMPANY}

  mutation linkCompanies($ids: [String!]!) {
    linkCompanies(input: { ids: $ids }) {
      ...getCompany
    }
  }
`;

export const ADD_UPDATE_FOREIGN_AGENT = gql`
  ${GET_FOREIGN_AGENT}
  mutation createForeignAgent(
    $id: String
    $displayName: String!
    $email: String!
  ) {
    createForeignAgent(
      input: { _id: $id, displayName: $displayName, email: $email }
    ) {
      ...getForeignAgent
    }
  }
`;

export const ADD_UPDATE_LOCAL_AGENCY = gql`
  ${GET_LOCAL_AGENCY}
  mutation createLocalAgency(
    $id: String
    $displayName: String!
    $email: String!
  ) {
    createLocalAgency(
      input: { _id: $id, displayName: $displayName, email: $email }
    ) {
      ...getLocalAgency
    }
  }
`;
