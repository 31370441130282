import { NavLink, Navigate } from "react-router-dom";
import { Form, FormikProps, Formik } from "formik";

import Link from "@mui/material/Link";

import { GoogleLogin } from "@react-oauth/google";

import Button from "@mui/material/Button";
//import Google from "./Google";
// import Facebook from "./Facebook";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockRounded from "@mui/icons-material/LockRounded";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { google, selectAuth, signInApollo, signInRecaptcha } from "./authSlice";
import { FormikTextField } from "../../components/Layout/FormikTextField";

import AuthSkeleton from "./AuthSkeleton";
import { loginSchema } from "./validation";
import Toast from "../../components/Layout/Toast";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";
import Divider from "@mui/material/Divider";
import { Container, Stack, useTheme } from "@mui/material";
import { UserCredentials } from "./types/authType";
import { createRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

// interface LocationState {
//   from: {
//     pathname: string
//   }
// }

interface Values {
  email: string;
  password: string;
  showPassword: boolean;
}

export const SignIn = () => {
  const { loading, error, user } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t: ta } = useTranslation("auth");
  const [recaptchaUser, setRecaptchaUser] = useState<UserCredentials>({
    email: "",
    password: "",
    captcha: "",
  });
  // const navigate = useNavigate()
  // const location = useLocation()
  if (user && user.email) {
    return <Navigate to="/app" />;
    // let { from } = location.search || { from: { pathname: '/app/dashboard' } }
    // navigate(from)
  }
  const recaptchaRef = createRef<ReCAPTCHA>();
  const onReCAPTCHAChange = async (captchaCode: string | undefined | null) => {
    // If the reCAPTCHA code is null or undefined indicating that
    // the reCAPTCHA was expired then return early
    if (!captchaCode) {
      return;
    }
    try {
      dispatch(signInRecaptcha({ ...recaptchaUser, captcha: captchaCode }));
    } catch (error) {
      //setError(error?.message || "Something went wrong");
    } finally {
      // Reset the reCAPTCHA when the request has failed or succeeded
      // so that it can be executed again if user submits another email.

      if (recaptchaRef.current) recaptchaRef.current.reset();
    }
  };
  return (
    <>
      <Helmet>
        <title>Sign In | Enjaz+</title>
      </Helmet>
      <Container maxWidth="sm">
        <Card elevation={3} sx={{ m: 2 }}>
          <CardContent>
            <Stack>
              <Stack sx={{ mb: 3, alignItems: "center" }}>
                <Typography variant="h2">{ta("signin")}</Typography>
              </Stack>

              {loading === "pending" ? (
                <AuthSkeleton />
              ) : (
                <>
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                      showPassword: false,
                    }}
                    validationSchema={loginSchema}
                    onSubmit={(values, actions) => {
                      actions.setSubmitting(false);
                      //recaptchaRef.current!.execute();
                      //setRecaptchaUser({ ...values });
                      dispatch(signInApollo(values));
                    }}
                  >
                    {(props: FormikProps<Values>) => (
                      <Form>
                        {/* <ReCAPTCHA
                          ref={recaptchaRef}
                          size="invisible"
                          sitekey={
                            process.env.REACT_APP_RECAPTCHA_SITE_KEY as string
                          }
                          onChange={onReCAPTCHAChange}
                        /> */}
                        <FormikTextField
                          formikKey="email"
                          label={ta("email")}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircle />
                              </InputAdornment>
                            ),
                          }}
                        />

                        <FormikTextField
                          formikKey="password"
                          label={ta("password")}
                          type={props.values.showPassword ? "text" : "password"}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockRounded />
                              </InputAdornment>
                            ),
                          }}
                        />

                        <Grid
                          container
                          spacing={3}
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ width: "100%" }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              textAlign:
                                theme.direction === "ltr" ? "left" : "right",
                            }}
                          >
                            <FormControlLabel
                              style={{ marginBottom: "0" }}
                              control={
                                <Switch
                                  checked={props.values.showPassword}
                                  onChange={props.handleChange("showPassword")}
                                  name="showPassword"
                                />
                              }
                              label={ta("showPassword")}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              textAlign:
                                theme.direction === "ltr" ? "right" : "left",
                            }}
                          >
                            <Link
                              component={NavLink}
                              underline="none"
                              to="/forgotPassword"
                            >
                              <>{ta("forgotPassword")}?</>
                            </Link>
                          </Grid>
                        </Grid>

                        {error && (
                          <Toast severity="error">{error.message}</Toast>
                        )}
                        <Box component="div">
                          <Button
                            sx={{ width: "100%", marginY: "8px" }}
                            type="submit"
                            color="secondary"
                            variant="contained"
                            disabled={!props.isValid}
                          >
                            {ta("signin")}
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                  <Stack direction="row" sx={{ my: 1, alignItems: "center" }}>
                    <Typography>{ta("doNotHaveAnAccount")}?</Typography>

                    <Link
                      component={NavLink}
                      underline="none"
                      to="/register"
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                    >
                      <>{ta("signUp")}</>
                    </Link>
                  </Stack>
                </>
              )}
              <Divider variant="middle" sx={{ my: 2 }}>
                {ta("or")}
              </Divider>

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    dispatch(google(credentialResponse.credential as string));
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  data-text="signin_with"
                  theme="filled_blue"
                  size="large"
                  data-logo_alignment="center"
                  data-shape="pill"
                  useOneTap
                />
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};
