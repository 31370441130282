import { ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";
import {
  Forgot,
  Profile,
  Reset,
  SignIn,
  SignUp,
  Verify,
} from "../features/auth";
import { Role } from "../features/auth/types/authType";
import { RoleTypes } from "../features/auth/types/roleTypes";
import Dashboard from "../features/dashboard/Dashboard";
import Error from "../features/error/Error";
import LandingPage from "../features/home/LandingPage";
import { Candidates } from "../features/process/Candidates";
import { CheckStatus } from "../features/process/CheckStatus";
import CvDetailCarousel from "../features/process/components/CvDetailCarousel";
import { EmployeeEntry } from "../features/process/EmployeeEntry";
import { Employees } from "../features/process/Employees";
import { VisaEntry } from "../features/process/VisaEntry";
import { Visas } from "../features/process/Visas";
import { Companies } from "../features/setups/Companies";
import { CompanyProfile } from "../features/setups/CompanyProfile";
import { Users } from "../features/setups/users";
import DashboardLayout from "./DashboardLayout";
import MainLayout from "./MainLayout";

let PrivilegedRoles: Role[] = [];
const routes = (isLoggedIn: Boolean, roles: Role[]) => {
  PrivilegedRoles = roles;
  return [
    {
      path: "/app",
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        {
          path: "dashboard",
          element: isPrivileged(<Dashboard />, RoleTypes.ViewDashboard),
        },

        { path: "profile", element: <Profile /> },
        { path: "cp", element: <CompanyProfile /> },
        { path: "cp/:id/:type", element: <CompanyProfile /> },
        { path: "cvs", element: <Candidates /> },
        { path: "cvd", element: <CvDetailCarousel /> },
        { path: "employees", element: <Employees /> },
        { path: "employee/:id", element: <EmployeeEntry /> },
        { path: "visas", element: <Visas /> },
        { path: "visa/:id", element: <VisaEntry /> },
        { path: "users", element: <Users /> },
        // { path: "user/:id", element: <User /> },
        { path: "c", element: <Companies /> },
        { path: "/app", element: <Navigate to="/app/cvs" /> },
        {
          path: "member",
          element: <Outlet />,
          children: [
            { path: "/app/member", element: <Profile /> },
            { path: "/app/member/add", element: <Profile /> },
          ],
        },
      ],
    },
    {
      path: "/",
      element: !isLoggedIn ? <MainLayout /> : <Navigate to="/app/cvs" />,
      children: [
        { path: "home", element: <LandingPage /> },
        { path: "ts", element: <CheckStatus /> },
        { path: "login", element: <SignIn /> },
        { path: "register", element: <SignUp /> },
        {
          path: "verify/:id/:token/:expires/:signature",
          element: <Verify />,
        },
        {
          path: "c/:link",
          element: <CvDetailCarousel />,
        },
        { path: "forgotPassword", element: <Forgot /> },
        { path: "reset/:id/:token", element: <Reset /> },
        { path: "404", element: <Error /> },
        { path: "/", element: <Navigate to="/home" /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
  ];
};

function isPrivileged(element: ReactNode, privileged: string): ReactNode {
  if (
    PrivilegedRoles.some((r) => r.displayName === privileged) ||
    PrivilegedRoles.some(
      (r) => r.displayName === privileged.replace("Add", "View")
    ) ||
    PrivilegedRoles.some(
      (r) => r.displayName === privileged.replace("View", "Add")
    )
  ) {
    return element;
  }

  return <Navigate to="/app/profile" />;
}
export default routes;
//privileged.substr(0, 3) === "Add" &&
// else {
//     if (
//       PrivilegedRoles.some(
//         (r) => r.displayName === privileged.replace("Add", "View")
//       )
//     )
