import { Stack, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function TypeWriter() {
  const { t: tl } = useTranslation("landing_page");
  const theme = useTheme();
  const [text, setText] = useState("");
  React.useEffect(() => {
    loop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const phrases: string[] = [
    tl("secured"),
    tl("easy_to_use"),
    tl("registerOnce"),
    tl("inThreeLanguages"),
  ];
  let i = 0;
  let j = 0;
  let currentPhrase: string[] = [];
  let isDeleting = false;
  let isEnd = false;

  function loop() {
    isEnd = false;
    setText(currentPhrase.join(""));
    if (i < phrases.length) {
      if (!isDeleting && j <= phrases[i].length) {
        currentPhrase.push(phrases[i][j]);
        j++;
        setText(currentPhrase.join(""));
      }

      if (isDeleting && j > 0) {
        currentPhrase.pop();
        j--;
        setText(currentPhrase.join(""));
      }

      if (j === phrases[i].length) {
        isEnd = true;
        isDeleting = true;
      }

      if (isDeleting && j === 0) {
        currentPhrase = [];
        isDeleting = false;
        i++;
        if (i === phrases.length) {
          i = 0;
        }
      }
    }
    const spedUp = 10;
    const normalSpeed = 50;
    const time = isEnd ? 2000 : isDeleting ? spedUp : normalSpeed;
    setTimeout(loop, time);
  }
  return (
    <Stack spacing={1}>
      <Typography variant="h2" component="div">
        {/* Enjaz+ is: <br /> */}
        <Typography
          fontWeight="700"
          variant="h3"
          sx={{
            color: theme.palette.primary.main,
            textAlign: { xs: "center", sm: "start" },
          }}
        >
          {text}|
        </Typography>
      </Typography>
    </Stack>
  );
}

export default TypeWriter;
