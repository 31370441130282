import { gql } from "@apollo/client";
import {
  GET_COMPANY,
  GET_FOREIGN_AGENT,
  GET_LOCAL_AGENCY,
} from "../fragments/setup";
export const GET_ALL_COMPANIES = gql`
  ${GET_COMPANY}
  query GetCompanies(
    $type: ClientTypes
    $searchText: String
    $skip: Int
    $take: Int
  ) {
    companies(type: $type, skip: $skip, take: $take, searchText: $searchText) {
      totalCount
      companies {
        ...getCompany
      }
    }
  }
`;

export const GET_ALL_FOREIGN_AGENTS = gql`
  ${GET_FOREIGN_AGENT}
  query GetForeignAgents($searchText: String, $skip: Int, $take: Int) {
    companies(skip: $skip, take: $take, searchText: $searchText) {
      totalCount
      companies {
        ...getForeignAgent
      }
    }
  }
`;

export const GET_SELECTED_COMPANY = gql`
  ${GET_COMPANY}

  query GetSelectedCompany($id: String!) {
    getCompany(input: { id: $id }) {
      ...getCompany
    }
  }
`;

export const GET_SELECTED_FOREIGN_AGENT = gql`
  ${GET_FOREIGN_AGENT}

  query GetSelectedForeignAgent($id: String!) {
    getForeignAgent(input: { id: $id }) {
      ...getForeignAgent
    }
  }
`;

export const GET_ALL_LOCAL_AGENCIES = gql`
  ${GET_LOCAL_AGENCY}
  query GetLocalAgencies($searchText: String, $skip: Int, $take: Int) {
    localAgencies(skip: $skip, take: $take, searchText: $searchText) {
      totalCount
      localAgencies {
        ...getLocalAgency
      }
    }
  }
`;

export const GET_SELECTED_LOCAL_AGENCY = gql`
  ${GET_LOCAL_AGENCY}
  query GetSelectedLocalAgency($id: String) {
    getLocalAgency(input: { id: $id }) {
      ...getLocalAgency
    }
  }
`;
