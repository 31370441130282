import { Attachment, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  Button,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { CompanyFilter } from "../../../components/filter/CompanyFilter";
import { VisaFilter } from "../../../components/filter/VisaFilter";
import { FormikTextField } from "../../../components/Layout/FormikTextField";
import { StyledAccordionSummary } from "../../../styles/componentStyled";
import { ClientTypes } from "../../auth/types/authType";
import { Company } from "../../setups/types/setupTypes";
import { CountriesList } from "../types/processTypes";

export const VisaBrief = () => {
  const [visaId, setVisaId] = useState<string>("");
  const [company, setCompany] = useState<Company>();
  const attachVisa = () => {
    //dispatch(linkCompanies([companyData?._id as string, company] as string[]));
  };
  return (
    <Stack>
      <Accordion>
        <StyledAccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" component="div">
            Search & Attach Visa
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} sx={{ mb: 1 }}>
            <Grid item md={8} xs={12}>
              <VisaFilter setVisaId={setVisaId} />
            </Grid>
            <Grid item md={4} xs={12}>
              <Button
                sx={{ width: "100%", mt: 1 }}
                color="secondary"
                variant="contained"
                onClick={attachVisa}
                disabled={!visaId || visaId.length === 0}
              >
                <Attachment />
                Attach Visa
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CompanyFilter
            companyType={ClientTypes.ForeignAgent}
            setCompany={setCompany}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikTextField formikKey="visa.visaNumber" label="Visa Number" />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikTextField
            formikKey="visa.fullName"
            label="Sponsor Full Name"
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikTextField formikKey="visa.passportNumber" label="Sponsor Id" />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikTextField
            formikKey="embassyProcess.enjazNumber"
            label="Enjaz Number"
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikTextField
            formikKey="visa.address.telephone"
            label="Sponsor Telephone"
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikTextField formikKey="visa.address.city" label="Sponsor city" />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikTextField
            formikKey="visa.address.country"
            select
            label="Sponsor Country"
          >
            {Object.keys(CountriesList).map((option) => (
              <MenuItem key={option} value={option as string}>
                {t((option as string).toLowerCase())}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
      </Grid>
    </Stack>
  );
};
