import { Delete, Add, Link } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CompanyFilter } from "../../components/filter/CompanyFilter";
//import { useTranslation } from "react-i18next";
import { FormikTextField } from "../../components/Layout/FormikTextField";
import { StyledTableRow, StyledTableCell } from "../../styles/tableStyles";
import { ClientTypes } from "../auth/types/authType";
import { addCompany, linkCompanies, selectSetups } from "./setupSlices";

import { Company, Partner } from "./types/setupTypes";
import { createCompanySchema } from "./validation";
import { selectAuth } from "../auth/authSlice";
import Toast from "../../components/Layout/Toast";

interface PartnersTabProps {
  companyData?: Company | undefined;
}
export const PartnersEntry = ({ companyData }: PartnersTabProps) => {
  //const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectAuth);
  const {
    loading: loadingAddingPartner,
    error,
    success,
    selectedCompany: addedPartner,
  } = useAppSelector(selectSetups);

  const [company, setCompany] = useState<Company>();

  let partner: Partner = { displayName: "", email: "" };
  let partners: Partner[] = [];
  if (companyData && companyData.partners) {
    partners = companyData.partners?.map(
      (p) =>
        ({ _id: p._id, displayName: p.displayName, email: p.email } as Partner)
    ) as Partner[];
  }

  const linkPartner = () => {
    if (!partners.map((c) => c._id as string).includes(company?._id as string))
      dispatch(
        linkCompanies([
          companyData?._id as string,
          company?._id as string,
        ] as string[])
      );
  };
  const removePartner = (id: string) => {};
  return (
    <>
      <Grid container spacing={1} sx={{ mb: 1 }}>
        <Grid item md={8} xs={12}>
          <CompanyFilter
            companyType={(companyData && companyData.type) as ClientTypes}
            setCompany={setCompany}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Button
            sx={{ width: "100%", mt: 1 }}
            color="secondary"
            variant="contained"
            onClick={linkPartner}
            disabled={
              !company ||
              loadingAddingPartner === "pending" ||
              company.displayName.length === 0
            }
          >
            <Link />
            Link Partner
          </Button>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-start" sx={{ mt: 0 }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>S.No</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {partners &&
                partners.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>{row.displayName}</StyledTableCell>
                    <StyledTableCell>{row.email}</StyledTableCell>

                    <StyledTableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => removePartner(row._id as string)}
                        size="large"
                      >
                        <Delete />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {companyData && (
        <Formik
          enableReinitialize={true}
          initialValues={partner as Partner}
          validationSchema={createCompanySchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            values = {
              ...values,
              type:
                companyData.type === ClientTypes.LocalAgency
                  ? ClientTypes.ForeignAgent
                  : ClientTypes.LocalAgency,
            };
            dispatch(addCompany(values));
          }}
        >
          {(props: FormikProps<Company>) => (
            <Form>
              <>
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <FormikTextField formikKey="displayName" label="Name" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormikTextField formikKey="email" label="Email" />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    {success && (
                      <Toast severity="success">{success.message}</Toast>
                    )}
                    {error && <Toast severity="error">{error.message}</Toast>}
                    <Button
                      sx={{ width: "100%", mt: 1 }}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={
                        !props.isValid || loadingAddingPartner === "pending"
                      }
                    >
                      <Add />
                      Add New Partner
                    </Button>
                  </Grid>
                </Grid>
              </>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
