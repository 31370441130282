import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { selectAuth } from "../../auth/authSlice";

import Toast from "../../../components/Layout/Toast";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Share } from "@mui/icons-material";
import { StyledLink } from "../../../styles/componentStyled";

interface LinkProp {
  link: string;
  employees: string;
}
const ShareCvs = ({ link, employees }: LinkProp) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [email, setEmail] = useState("");
  const { t: ta } = useTranslation("auth");
  const { error } = useAppSelector(selectAuth);
  const isEmailValid =
    email.length > 0 &&
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);

  // (email.length < 5 || !email.includes(".") || !email.includes("@"));

  const dispatch = useAppDispatch();
  const shareLink = () => {};
  return (
    <Card>
      <CardHeader subheader={ta("share")} title={ta("shareLinks")} />
      <Divider />
      <CardContent>
        <TextField
          fullWidth
          sx={{ mt: 1 }}
          name="link"
          value={link}
          label={ta("link")}
          disabled
        />
        <TextField
          fullWidth
          sx={{ mt: 1 }}
          name="employees"
          value={employees}
          label={ta("employees")}
          disabled
        />
        <TextField
          fullWidth
          sx={{ mt: 1 }}
          name="email"
          label={ta("email")}
          value={email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
          }}
          error={isEmailValid as boolean}
          helperText={isEmailValid ? "Email is not correct" : ""}
        />
        <Box m={1}>
          {error && <Toast severity="error">{error.message}</Toast>}
        </Box>
      </CardContent>
      <Divider />
      <Stack
        direction={isSmallScreen ? "column" : "row"}
        spacing={1}
        sx={{
          justifyContent: "space-between",
          p: 2,
          margin: "auto",
          marginBottom: theme.spacing(2),
        }}
      >
        <Button
          onClick={shareLink}
          color="secondary"
          variant="contained"
          disabled={!isEmailValid}
        >
          <Share /> {ta("shareByEmail")}
        </Button>
        <StyledLink
          aria-label="Chat on WhatsApp"
          href={`https://wa.me/?text=https://enjazplus.com/c/${link}?lng=ar`}
          target={"_blank"}
          sx={{
            padding: "8px",
            color: "white",
            backgroundColor: "#075E54",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {" "}
          <img
            alt="Chat on WhatsApp"
            src="/Digital_Glyph_White.svg"
            width="50px"
            height="30px"
          />
          {ta("shareByWhatsApp")}
        </StyledLink>{" "}
      </Stack>
    </Card>
  );
};

export default ShareCvs;
