import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "dayjs/locale/ar-sa";
import "dayjs/locale/am";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { NavLink as RouterLink, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import { StyledAccordionSummary } from "../../styles/componentStyled";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import {
  Button,
  Divider,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Add, Backspace, Print } from "@mui/icons-material";

import Toast from "../../components/Layout/Toast";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  createEmployee,
  getEmployee,
  resetSelectedEmployee,
  selectProcesses,
} from "./processSlices";
//import Barcode, { Renderer } from "react-jsbarcode";
import { Employee, RequiredDocuments, Sex } from "./types/processTypes";
import {
  changePageTitle,
  selectPreference,
} from "../preferences/preferencesSlice";
import { FormikTextField } from "../../components/Layout/FormikTextField";
import { Form, Formik, FormikProps } from "formik";
import { createEmployeeSchema } from "./validation";
import Save from "@mui/icons-material/Save";
import { addYears } from "date-fns";
import { EmployeeTabs } from "./components/EmployeeTabs";
import { useTranslation } from "react-i18next";
import FacePhoto from "./components/FacePhoto";
import { usePDF } from "@react-pdf/renderer";
import { SaudiApplication } from "../../components/reports/embassy/SaudiApplication";
import { AuthUser, ClientTypes } from "../auth/types/authType";
import { Company } from "../setups/types/setupTypes";
import { CompanyFilter } from "../../components/filter/CompanyFilter";
import { selectAuth } from "../auth/authSlice";
export const EmployeeEntry = () => {
  const { id } = useParams() as {
    id: string;
  };

  const theme = useTheme();
  const [company, setCompany] = useState<Company>();
  const { t, i18n } = useTranslation();
  const { t: te } = useTranslation("enums");
  const { user } = useAppSelector(selectAuth);
  const { selectedEmployee, error, success } = useAppSelector(selectProcesses);

  const { locale } = useAppSelector(selectPreference);

  const application = SaudiApplication({
    employee: selectedEmployee,
    user: user as AuthUser,
  });
  const [instance, updateInstance] = usePDF({
    document: application,
  });

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (selectedEmployee) {
      updateInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmployee]);

  useEffect(() => {
    dispatch(changePageTitle(`Employee Entry`));

    if (id && id !== "0") {
      dispatch(getEmployee(id));
    } else {
      resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  function resetFields() {
    dispatch(resetSelectedEmployee());
  }

  function printPdf() {
    //console.log(instance);
    if (instance && instance.url) {
      const iframe = document.createElement("iframe"); //load content in an iframe to print later
      document.body.appendChild(iframe);

      iframe.style.display = "none";
      iframe.src = instance.url as string;
      iframe.onload = function () {
        setTimeout(function () {
          iframe.focus();
          iframe.contentWindow?.print();
        }, 1);
      };
    }
  }

  return (
    <div dir={i18n.dir()}>
      <Helmet>
        <title>
          {`${selectedEmployee?.givenName?.toUpperCase() as string} | Enjaz+`}
        </title>
      </Helmet>
      <Box
        sx={{
          m: { xs: 0, md: 1 },
          px: { xs: 0, md: 2 },
        }}
      >
        <>
          <Formik
            enableReinitialize={true}
            initialValues={selectedEmployee as Employee}
            validationSchema={createEmployeeSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              if (company) {
                values = {
                  ...values,
                  owner: company,
                };
              } else if (user && user.company) {
                values = {
                  ...values,
                  owner: user.company,
                };
              }
              //console.log(values);
              dispatch(createEmployee(values));
            }}
          >
            {(props: FormikProps<Employee>) => (
              <Form>
                <Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Tooltip title="Back to Employee list">
                      <Button
                        color="secondary"
                        variant="contained"
                        component={RouterLink}
                        to={"/app/employees"}
                      >
                        <Backspace />
                      </Button>
                    </Tooltip>
                    <Stack
                      direction="row"
                      spacing={1}
                      alignSelf="flex-end"
                      sx={{
                        "& > :not(style)": {
                          m: 0,
                          ml: 1,
                        },
                      }}
                    >
                      <Tooltip title={t("print")}>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={printPdf}
                        >
                          <Print />{" "}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title={
                          t("save") + " " + t("employee") + " " + t("data")
                        }
                      >
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                          disabled={!props.isValid}
                        >
                          <Save />{" "}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title={
                          t("addNew") + " " + t("employee") + " " + t("data")
                        }
                      >
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={resetFields}
                        >
                          <Add />{" "}
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Stack>
                  <Divider variant="middle" sx={{ mt: 2 }} />
                  <Accordion sx={{ my: 1 }} expanded>
                    <StyledAccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h5" component="div">
                        Passport Data
                      </Typography>
                    </StyledAccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="space-between"
                      >
                        <Grid container item spacing={1} md={8} xs={12}>
                          {user &&
                            user.company &&
                            user.company.type === ClientTypes.ForeignAgent && (
                              <Grid item xs={12}>
                                <CompanyFilter
                                  companyType={ClientTypes.LocalAgency}
                                  setCompany={setCompany}
                                />
                              </Grid>
                            )}
                          <Grid item md={4} xs={12}>
                            <FormikTextField
                              formikKey="givenName"
                              label={t("givenName")}
                              sx={{ mt: 1 }}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <FormikTextField
                              formikKey="surName"
                              label={t("surName")}
                              sx={{ mt: 1 }}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <FormikTextField
                              formikKey="sex"
                              select
                              label={te("sex")}
                            >
                              {Object.keys(Sex).map((option) => (
                                <MenuItem key={option} value={option as string}>
                                  {te(option)}
                                </MenuItem>
                              ))}
                            </FormikTextField>
                          </Grid>

                          <Grid item md={4} xs={12}>
                            <FormikTextField
                              formikKey="passportNumber"
                              label={t("passportNumber")}
                              sx={{ mt: 1 }}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={locale}
                            >
                              <DatePicker
                                label={t("dateOfBirth")}
                                views={["day", "month", "year"]}
                                minDate={new Date("1980-01-01")}
                                disableMaskedInput
                                inputFormat="DD-MMM-YYYY"
                                value={props.values.dateOfBirth}
                                onChange={(value) => {
                                  props.setFieldValue(
                                    "dateOfBirth",
                                    value,
                                    true
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{ mt: 1 }}
                                    fullWidth
                                    size="small"
                                    error={Boolean(
                                      props.touched.dateOfBirth &&
                                        props.errors.dateOfBirth
                                    )}
                                    helperText={
                                      props.touched.dateOfBirth &&
                                      props.errors.dateOfBirth
                                    }
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <FormikTextField
                              formikKey="placeOfBirth"
                              label={t("placeOfBirth")}
                              sx={{ mt: 1 }}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <FormikTextField
                              formikKey="placeOfIssue"
                              label={t("placeOfIssue")}
                              sx={{ mt: 1 }}
                            />
                          </Grid>

                          <Grid item md={4} xs={12}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={locale}
                            >
                              <DatePicker
                                label={t("dateOfIssue")}
                                views={["day", "month", "year"]}
                                minDate={new Date("2017-01-01")}
                                maxDate={new Date("2023-01-01")}
                                disableMaskedInput
                                inputFormat="DD-MMM-YYYY"
                                value={props.values.passportIssueDate}
                                onChange={(value) => {
                                  props.setFieldValue(
                                    "passportIssueDate",
                                    value,
                                    true
                                  );
                                  props.setFieldValue(
                                    "passportExpiryDate",
                                    addYears(value as Date, 5),
                                    true
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{ mt: 1 }}
                                    fullWidth
                                    size="small"
                                    error={Boolean(
                                      props.touched.passportIssueDate &&
                                        props.errors.passportIssueDate
                                    )}
                                    helperText={
                                      props.touched.passportIssueDate &&
                                      props.errors.passportIssueDate
                                    }
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={locale}
                            >
                              <DatePicker
                                label={t("dateOfExpiry")}
                                views={["day", "month", "year"]}
                                minDate={new Date("2023-01-01")}
                                maxDate={new Date("2028-01-01")}
                                disableMaskedInput
                                inputFormat="DD-MMM-YYYY"
                                value={props.values.passportExpiryDate}
                                onChange={(value) => {
                                  props.setFieldValue(
                                    "passportExpiryDate",
                                    value,
                                    true
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{ mt: 1 }}
                                    fullWidth
                                    size="small"
                                    error={Boolean(
                                      props.touched.passportExpiryDate &&
                                        props.errors.passportExpiryDate
                                    )}
                                    helperText={
                                      props.touched.passportExpiryDate &&
                                      props.errors.passportExpiryDate
                                    }
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <FormikTextField
                              formikKey="documents"
                              select
                              multiline
                              rows={4}
                              label="Required Documents"
                              SelectProps={{
                                multiple: true,
                                displayEmpty: true,
                                defaultValue: [],
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 48 * 4.5 + 8,
                                      width: 250,
                                    },
                                  },

                                  variant: "menu",
                                },
                              }}
                            >
                              {Object.keys(RequiredDocuments).map((option) => (
                                <MenuItem
                                  key={option}
                                  value={option as string}
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor:
                                        theme.palette.action.selected,
                                    },
                                    "&.Mui-selected:hover": {
                                      backgroundColor:
                                        theme.palette.action.hover,
                                    },
                                  }}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </FormikTextField>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          item
                          md={4}
                          xs={12}
                          sx={{ justifyContent: "flex-end" }}
                        >
                          <FacePhoto />
                        </Grid>
                      </Grid>
                      <EmployeeTabs formikData={props} />
                      <br />
                      {success && (
                        <Toast severity="success">{success.message}</Toast>
                      )}
                      {error && <Toast severity="error">{error.message}</Toast>}
                    </AccordionDetails>
                  </Accordion>
                </Stack>
              </Form>
            )}
          </Formik>

          {error && <Toast severity="error">{error.message}</Toast>}
        </>
      </Box>
    </div>
  );
};
