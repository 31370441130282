import { Divider, Grid, MenuItem } from "@mui/material";
import { t } from "i18next";
import { FormikTextField } from "../../../components/Layout/FormikTextField";
import { CountriesList } from "../types/processTypes";

export const ContactPerson = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <FormikTextField formikKey="contactPerson.displayName" label="Name" />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikTextField formikKey="contactPerson.kinship" label="Kinship" />
        </Grid>
      </Grid>
      <Divider variant="fullWidth" textAlign="left" sx={{ mt: 1 }}>
        Address
      </Divider>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <FormikTextField
            formikKey="contactPerson.address.telephone"
            label="Telephone"
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikTextField
            formikKey="contactPerson.address.city"
            label="City"
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikTextField
            formikKey="contactPerson.address.country"
            select
            label={t("country")}
          >
            {Object.keys(CountriesList).map((option) => (
              <MenuItem key={option} value={option as string}>
                {t((option as string).toLowerCase())}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
      </Grid>
    </div>
  );
};
