import { experimentalStyled as styled } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "4px",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: 48,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: 32,
  },
}));
export const StyledTableCellLabel = styled(StyledTableCell)(({ theme }) => ({
  textAlign: theme.direction === "rtl" ? "right" : "left",
  fontWeight: 900,
  color: theme.palette.primary.main,
}));
export const StyledTableCellValue = styled(StyledTableCell)(({ theme }) => ({
  textAlign: theme.direction === "rtl" ? "right" : "left",
  fontWeight: 900,
  color: theme.palette.secondary.main,
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.selected,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
