import { gql } from "@apollo/client";
export const GET_COMPANY = gql`
  fragment getCompany on Company {
    _id
    type
    displayName
    email
    address {
      city
      telephone
      country
    }
    partners {
      _id
      type
      displayName
      email
      address {
        city
        telephone
        country
      }
    }
  }
`;
export const GET_FOREIGN_AGENT = gql`
  fragment getForeignAgent on ForeignAgent {
    _id
    displayName
    email
    address {
      city
      telephone
      country
    }
    partners {
      _id
      displayName
      email
    }
  }
`;

export const GET_LOCAL_AGENCY = gql`
  fragment getLocalAgency on LocalAgency {
    _id
    displayName
    email
    address {
      city
      telephone
      country
    }
    partners {
      _id
      displayName
      email
    }
  }
`;
