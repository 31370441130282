import { useEffect, useState } from "react";
import { Autocomplete, LinearProgress, TextField } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { ClientTypes } from "../../features/auth/types/authType";
import { Company } from "../../features/setups/types/setupTypes";
import { selectAuth } from "../../features/auth/authSlice";

interface Props {
  setCompany: any;
  companyType: ClientTypes;
  companyId?: string | undefined;
}
export const CompanyFilter = ({
  setCompany,
  companyType,
  companyId,
}: Props) => {
  //const dispatch = useAppDispatch();

  const [selectedCompany, setSelectedCompany] = useState<Company>();
  const [companies, setCompanies] = useState<Company[]>([]);
  const { user, loading: loadingForeignAgent } = useAppSelector(selectAuth);

  useEffect(() => {
    if (user && user.company && user.company.partners) {
      setCompanies(user.company.partners);
    }
  }, [companyType, user]);

  useEffect(() => {
    if (companies?.length > 0) {
      const defaultCompany =
        companyId && companyId.length > 0
          ? (companies.find((c) => c._id === companyId) as Company)
          : (companies[0] as Company);
      console.log(companyId);
      console.log(defaultCompany);
      setCompany(defaultCompany);
      setSelectedCompany(defaultCompany);
    }
  }, [companies, setCompany, companyId]);
  return (
    <>
      {loadingForeignAgent === "pending" || companies.length < 1 ? (
        <LinearProgress color="secondary" />
      ) : (
        // Search and Link your partner
        <Autocomplete
          id="companyId"
          options={companies}
          value={selectedCompany}
          getOptionLabel={(option) => option.displayName + " " + option.email}
          sx={{ mt: 1 }}
          defaultValue={companies[0]}
          loading={loadingForeignAgent !== "idle" ? true : false}
          onChange={(e, value) => {
            setSelectedCompany(value as Company);
            setCompany(value as Company);
          }}
          size="small"
          renderInput={(params) => (
            <TextField
              label={companyType}
              name="companyId"
              {...params}
              variant="outlined"
            />
          )}
        />
      )}
    </>
  );
};
