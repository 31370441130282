export enum RoleTypes {
  ViewDashboard = "ViewDashboard",

  Options = "Options",

  ViewCompanies = "ViewCompanies",

  ViewUsers = "ViewUsers",
  ManageUsers = "ManageUsers",

  ViewRoles = "ViewRoles",
  ManageRoles = "ManageRoles",
  ManageUserRoles = "ManageUserRoles",

  AgencyManagement = "AgencyManagement",
  ForeignAgentsManagement = "ForeignAgentsManagement",

  ViewCandidates = "ViewCandidates",

  ViewEmployees = "ViewEmployees",
  AddEmployees = "AddEmployees",
  EditEmployees = "EditEmployees",
  DeleteEmployees = "DeleteEmployees",
  EmployeeReports = "EmployeeReports",

  ViewVisas = "ViewVisas",
  AddVisas = "AddVisas",
  EditVisas = "EditVisas",
  DeleteVisas = "DeleteVisas",
  AssignVisas = "AssignVisas",
  ReleaseVisas = "ReleaseVisas",

  OpenComplains = "OpenComplains",
  EditComplains = "EditComplains",
  ClosComplains = "ClosComplains",
  ReOpenComplains = "ReOpenComplains",
  ConfirmComplains = "ConfirmComplains",

  LabourEntry = "LabourEntry",
  DiscontinueProcess = "DiscontinueProcess",
  DeleteLabourData = "DeleteLabourData",
  LabourReports = "LabourReports",

  EmbassyEntry = "EmbassyEntry",
  DeleteEmbassyData = "DeleteEmbassyData",
  EmbassyReports = "EmbassyReports",
  EnjazEntry = "EnjazEntry",

  FlightEntry = "FlightEntry",
  DeleteFlightData = "DeleteFlightData",
  FlightReports = "FlightReports",
  AfterFlightEntry = "AfterFlightEntry",

  ViewReports = "ViewReports",
}
