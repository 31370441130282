import React from "react";
import { TablePagination } from "@mui/material";

interface Props {
  total: number;
  rowsPerPage: number;
  currentPage: number;
  setRowsPerPage: any;
  setCurrentPage: any;
}

function Paging({
  total,
  rowsPerPage,
  currentPage,
  setRowsPerPage,
  setCurrentPage,
}: Props) {
  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setCurrentPage(page);
  };
  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const rows = parseInt(event.target.value);
    setCurrentPage(0);
    setRowsPerPage(rows);
  };
  return (
    <TablePagination
      component="div"
      count={total}
      onPageChange={handlePageChange}
      page={currentPage}
      rowsPerPage={rowsPerPage}
      showFirstButton
      showLastButton
      rowsPerPageOptions={[
        2,
        5,
        10,
        25,
        50,
        100,
        { value: total, label: "All" },
      ]}
      onRowsPerPageChange={handleRowsPerPageChange}
      sx={{
        backgroundColor: "red !important", // gets overridden if not important
        height: "70px",
        ".MuiInputBase-root": {
          backgroundColor: "green",
        },
        ".MuiTablePagination-toolbar": {
          backgroundColor: "pink",
          width: "auto",
          color: "rgb(41, 39, 39)",
          height: "35px",
        },
        ".MuiBox-root": {
          backgroundColor: "yellow",
          color: "black",

          "& .MuiSvgIcon-root": {
            backgroundColor: "purple",
            color: "black",
          },
        },
      }}
      SelectProps={{
        MenuProps: {
          sx: {
            ".MuiPaper-root": {
              backgroundColor: "rosybrown",
              color: "black",
            },
            ".MuiTablePagination-menuItem": {
              ":hover": {
                backgroundColor: "turquoise",
              },
              backgroundColor: "yellow",
            },
            ".MuiTablePagination-menuItem.Mui-selected": {
              ":hover": {
                backgroundColor: "blue",
              },
              backgroundColor: "purple",
            },
          },
        },
      }}
    ></TablePagination>
  );
}

export default Paging;
