import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, Container, Grid } from "@mui/material";

import { useAppDispatch } from "../../app/hooks";
import { changePageTitle } from "../preferences/preferencesSlice";

const Dashboard = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changePageTitle("Dashboard"));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Dashboard | Enjaz+</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={3} sm={6} xl={3} xs={12}></Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}></Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}></Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}></Grid>

            <Grid item md={6} xs={12}></Grid>
            <Grid item md={6} xs={12}></Grid>

            <Grid item xs={12}></Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
