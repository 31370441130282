import { Card, CardContent, CardMedia, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

function About(): ReactElement {
  const { t: tl } = useTranslation("landing_page");
  return (
    <Box id="about">
      <Container maxWidth="lg">
        <Stack alignItems="center" sx={{ padding: "24px" }}>
          <Typography data-aos="fade-up" variant="h2" color="orange" noWrap>
            About Enjaz+
          </Typography>
          <Divider
            data-aos="zoom-in"
            orientation="horizontal"
            sx={{
              width: { xs: "220px", sm: "400px" },
              backgroundColor: "#008000",
              height: "4px",
              marginBottom: "10px",
            }}
          ></Divider>
          <Grid container spacing={3} justifyItems="center" color="black">
            <Grid item xs={12} sm={6} md={4}>
              <Card
                elevation={20}
                sx={{ maxWidth: 345, height: 600 }}
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <CardMedia
                  sx={{ height: 250 }}
                  image="../../../keeps_everything.svg"
                  title="reliable applications"
                />
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    fontWeight="900"
                    color="orange"
                  >
                    Keeps Everything
                  </Typography>
                  <Divider orientation="horizontal" flexItem />
                  <Typography
                    sx={{ mt: 1, fontSize: { xs: "0.9rem", sm: "1.2rem" } }}
                    component="div"
                    variant="body1"
                    color="text.secondary"
                    textAlign="justify"
                  >
                    {tl("keeps_everything")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                elevation={20}
                sx={{ maxWidth: 345, height: 600 }}
                data-aos="fade-up"
                data-aos-delay="60"
              >
                <CardMedia
                  sx={{ height: 250 }}
                  image="../../../mistake_less.svg"
                  title="more than websites"
                />
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    fontWeight="900"
                    color="orange"
                  >
                    Mistake Less
                  </Typography>
                  <Divider orientation="horizontal" flexItem />

                  <Typography
                    sx={{ mt: 1, fontSize: { xs: "0.9rem", sm: "1.2rem" } }}
                    component="div"
                    variant="body1"
                    color="text.secondary"
                    textAlign="justify"
                  >
                    {tl("mistake_less")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                elevation={20}
                sx={{ maxWidth: 345, height: 600 }}
                data-aos="fade-up"
                data-aos-delay="70"
              >
                <CardMedia
                  sx={{ height: 250 }}
                  image="../../../all_in_one.svg"
                  title="solution partner"
                />
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    fontWeight="900"
                    color="orange"
                  >
                    All In One
                  </Typography>
                  <Divider orientation="horizontal" flexItem />

                  <Typography
                    sx={{ mt: 1, fontSize: { xs: "0.9rem", sm: "1.2rem" } }}
                    component="div"
                    variant="body1"
                    color="text.secondary"
                    textAlign="justify"
                  >
                    {tl("all_in_one")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                elevation={20}
                sx={{ maxWidth: 345, height: 600 }}
                data-aos="fade-up"
                data-aos-delay="60"
              >
                <CardMedia
                  sx={{ height: 250 }}
                  image="../../../reports.svg"
                  title="more than websites"
                />
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    fontWeight="900"
                    color="orange"
                  >
                    Reports
                  </Typography>
                  <Divider orientation="horizontal" flexItem />

                  <Typography
                    sx={{ mt: 1, fontSize: { xs: "0.9rem", sm: "1.2rem" } }}
                    component="div"
                    variant="body1"
                    color="text.secondary"
                    textAlign="justify"
                  >
                    {tl("reports")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                elevation={20}
                sx={{ maxWidth: 345, height: 600 }}
                data-aos="fade-up"
                data-aos-delay="70"
              >
                <CardMedia
                  sx={{ height: 250 }}
                  image="../../../alerts.svg"
                  title="solution partner"
                />
                <CardContent>
                  <Typography
                    variant="h4"
                    component="h4"
                    fontWeight="900"
                    color="orange"
                  >
                    Alerts
                  </Typography>
                  <Divider orientation="horizontal" flexItem />

                  <Typography
                    sx={{ mt: 1, fontSize: { xs: "0.9rem", sm: "1.2rem" } }}
                    component="div"
                    variant="body1"
                    color="text.secondary"
                    textAlign="justify"
                  >
                    {tl("alerts")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}

export default About;
