import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { differenceInMonths } from "date-fns";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchEmployees,
  fetchShortLinks,
  generateLink,
  resetSuccess,
  selectProcesses,
  setSelectedEmployee,
} from "../process/processSlices";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

import {
  changePageTitle,
  selectPreference,
} from "../preferences/preferencesSlice";
//import Avatar from "@mui/material/Avatar";
import {
  Stack,
  Button,
  Typography,
  IconButton,
  Skeleton,
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  useTheme,
  FormControlLabel,
  Switch,
  Accordion,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore, Share } from "@mui/icons-material";
import Image from "mui-image";

import Paging from "../../components/Layout/Paging";
import { EmployeeStatus, ShortLink } from "./types/processTypes";
import {
  StyledTableRow,
  StyledTableCellLabel,
  StyledTableCellValue,
  StyledTableCell,
} from "../../styles/tableStyles";
import { useTranslation } from "react-i18next";
import CustomDialog from "../../components/modals/CustomDialog";
import { StyledAccordionSummary } from "../../styles/componentStyled";
import ShareCvs from "./components/ShareCvs";
import { t } from "i18next";
import { VisaBrief } from "./components/VisaBrief";
import { RowMenu } from "./components/RowMenu";

export const Candidates = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t: tt } = useTranslation();
  const { t: te } = useTranslation("enums");
  const {
    employeesWithCount: { employees, totalCount },
    sharedLinks,
    selectedShortLink,
    loading,
    success,
  } = useAppSelector(selectProcesses);
  //const { Employee } = useAppSelector(selectAuth);
  const { searchText } = useAppSelector(selectPreference);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openVisa, setOpenVisa] = useState(false);
  const [shortLink, setShortLink] = useState<ShortLink>({});
  const [selectCandidates, setSelectCandidates] = useState<boolean[]>([false]);
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();

  const viewCvHandler = (id: string) => {
    dispatch(setSelectedEmployee(employees.find((e) => e._id === id)));
    navigate("/app/cvd");
  };
  const dialogClose = () => {
    setOpen(false);
    dispatch(resetSuccess());
  };
  const dialogCloseVisa = () => {
    setOpenVisa(false);
    dispatch(resetSuccess());
  };
  useEffect(() => {
    if (success && selectedShortLink && selectedShortLink.link) {
      setOpen(true);
      setShortLink(selectedShortLink);
    }
  }, [success, selectedShortLink]);

  useEffect(() => {
    dispatch(changePageTitle("Candidates List"));

    const skipRows = currentPage * rowsPerPage;

    dispatch(
      fetchEmployees({
        searchText,
        skip: skipRows,
        take: rowsPerPage,
        status: EmployeeStatus.new,
      })
    );
  }, [dispatch, searchText, currentPage, rowsPerPage]); //

  useEffect(() => {
    dispatch(fetchShortLinks(""));
  }, [dispatch]);

  useEffect(() => {
    setTotal(totalCount as number);
    const checkBoxes = [...Array(totalCount)].map((e, i) => {
      return false;
    });
    setSelectCandidates(checkBoxes);
    //console.log(checkBoxes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount]);

  const shareList = () => {
    const employeeIds: string[] = [];
    for (let i = 0; i < totalCount; i++) {
      if (selectCandidates[i] === true)
        employeeIds.push(employees[i]._id as string);
    }
    dispatch(generateLink(employeeIds));
    //console.log(employeeIds);
  };
  const shareSingle = (employeeId: string) => {
    dispatch(generateLink([employeeId]));
  };
  const selectCandidate = (employeeId: string) => {
    setOpenVisa(true);
  };
  const openShareDialogue = (linkId: string) => {
    setOpen(true);
    setShortLink(sharedLinks.find((s) => s._id === linkId) as ShortLink);
  };
  const changeSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedAll = event.target.checked;
    setSelectAll(selectedAll);
    const checkBoxes = [...Array(totalCount)].map((e, i) => {
      return selectedAll;
    });
    setSelectCandidates(checkBoxes);
    //console.log(selectCandidates);
  };

  const changeCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const tempSelected = [...selectCandidates];
    tempSelected[index] = event.target.checked;
    setSelectCandidates(tempSelected);
  };

  return (
    <>
      <Helmet>
        <title>Candidates | Enjaz+</title>
      </Helmet>
      <Box
        sx={{
          m: { xs: 0, md: 1 },
          px: { xs: 0, md: 2 },
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          justifyItems="center"
          sx={{
            "& > :not(style)": {
              m: 0,
              ml: 1,
            },
          }}
        >
          <FormControlLabel
            style={{ marginBottom: "0" }}
            control={
              <Switch
                checked={selectAll}
                onChange={(e) => changeSelectAll(e)}
                inputProps={{ "aria-label": "controlled" }}
                name="selectAll"
                color="secondary"
              />
            }
            label="Select All"
          />
          <Button
            color="secondary"
            variant="contained"
            onClick={shareList}
            size="large"
            sx={{ mt: 1 }}
            disabled={!selectCandidates.some((c) => c === true)}
          >
            <Share />
          </Button>
        </Stack>
        <>
          <Grid
            container
            justifyContent="flex-start"
            sx={{ mt: 1 }}
            spacing={1}
          >
            {loading === "pending"
              ? [...Array(10)].map((e, i) => (
                  <Grid
                    container
                    item
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    direction="column"
                    display="flex"
                    alignItems="center"
                    key={i}
                  >
                    <Skeleton
                      variant="rounded"
                      width={180}
                      height={180}
                      style={{ margin: "5px" }}
                    />
                    <Skeleton variant="text" height={25} width={180} />
                    <Skeleton variant="text" height={25} width={180} />
                  </Grid>
                ))
              : employees.map((row, index) => (
                  <Grid
                    container
                    item
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    direction="column"
                    display="flex"
                    alignItems="center"
                    key={index}
                  >
                    <Button onClick={() => viewCvHandler(row._id as string)}>
                      <Image
                        src={
                          row && row.facePhoto
                            ? (row.facePhoto as string)
                            : "/blankShort.jpg"
                        }
                        width={180}
                        height={180}
                        shift="top"
                        distance="2rem"
                        shiftDuration={320}
                        style={{ borderRadius: "50%" }}
                      />
                    </Button>
                    <TableContainer
                      component={Paper}
                      sx={{
                        marginTop: 1,
                        borderRadius: "10px",
                        width: "100%",
                      }}
                    >
                      <Table size="small" aria-label="simple table">
                        <TableHead>
                          <StyledTableRow key="3">
                            <StyledTableCellLabel
                              colSpan={4}
                              sx={{ textAlign: "center" }}
                            >
                              {row.givenName + " (" + row.passportNumber + ")"}
                            </StyledTableCellLabel>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          <StyledTableRow key="1">
                            <StyledTableCellLabel>
                              {tt("age")}
                            </StyledTableCellLabel>
                            <StyledTableCellValue>
                              {Math.floor(
                                differenceInMonths(
                                  new Date(),
                                  new Date(row.dateOfBirth as Date)
                                ) / 12
                              ).toLocaleString(
                                theme.direction === "rtl" ? "ar-SA" : "en-US"
                              )}
                            </StyledTableCellValue>

                            <StyledTableCellLabel>
                              {tt("salary")}
                            </StyledTableCellLabel>
                            <StyledTableCellValue>
                              {row.additionalData?.salary?.toLocaleString(
                                theme.direction === "rtl" ? "ar-SA" : "en-US"
                              ) +
                                "" +
                                te(row.additionalData?.currency as string)}
                            </StyledTableCellValue>
                          </StyledTableRow>
                          <StyledTableRow key="0">
                            <StyledTableCellLabel>
                              {te("arabic")}
                            </StyledTableCellLabel>
                            <StyledTableCellValue>
                              {te(row.education?.arabicLanguage as string)}
                            </StyledTableCellValue>

                            <StyledTableCellLabel>
                              {te("english")}
                            </StyledTableCellLabel>
                            <StyledTableCellValue>
                              {te(row.education?.englishLanguage as string)}
                            </StyledTableCellValue>
                          </StyledTableRow>
                          <StyledTableRow key="5">
                            <StyledTableCellLabel>
                              {tt("workExperience")}
                            </StyledTableCellLabel>
                            <StyledTableCellValue colSpan={3}>
                              {row.workExperience &&
                              row.workExperience.length <= 0 ? (
                                <>{tt("firstTimer")}</>
                              ) : (
                                <>
                                  {te(
                                    (row.workExperience &&
                                      row.workExperience[0].country) as string
                                  ) +
                                    "-" +
                                    te(
                                      (row.workExperience &&
                                        row.workExperience[0]
                                          .profession) as string
                                    ) +
                                    "-" +
                                    te(
                                      (row.workExperience &&
                                        row.workExperience[0].years) as string
                                    )}
                                </>
                              )}
                            </StyledTableCellValue>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="space-between"
                      sx={{ width: "100%" }}
                    >
                      <Switch
                        checked={
                          selectCandidates[index]
                            ? selectCandidates[index]
                            : false
                        }
                        onChange={(e) => changeCheckBox(e, index)}
                        inputProps={{ "aria-label": "controlled" }}
                        name={index.toString()}
                        color="secondary"
                      />
                      <RowMenu
                        row={row}
                        selectCandidate={selectCandidate}
                        viewCvHandler={viewCvHandler}
                        shareSingle={shareSingle}
                      />
                    </Stack>
                  </Grid>
                ))}
          </Grid>

          <Stack spacing={1}>
            <Paging
              total={total}
              rowsPerPage={rowsPerPage}
              currentPage={currentPage}
              setRowsPerPage={setRowsPerPage}
              setCurrentPage={setCurrentPage}
            />
            <Typography variant="body2" color="textPrimary" component="div">
              Number of Candidates: {total}
            </Typography>
          </Stack>
        </>
        <Accordion>
          <StyledAccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6" component="div">
              <Share />
              Shared Links
            </Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Stack>
              {sharedLinks && sharedLinks.length > 1 && (
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>{t("sno")}</StyledTableCell>
                        <StyledTableCell>{t("link")}</StyledTableCell>
                        <StyledTableCell>{t("employees")}</StyledTableCell>

                        <StyledTableCell>{t("actions")}</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {sharedLinks.map((row, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>{row.link}</StyledTableCell>
                          <StyledTableCell>
                            {row.employees?.map((e) => e.givenName).join(", ")}
                          </StyledTableCell>

                          <StyledTableCell>
                            <IconButton
                              color="secondary"
                              onClick={() =>
                                openShareDialogue(row._id as string)
                              }
                              size="large"
                            >
                              <Share />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Stack>
          </AccordionDetails>
        </Accordion>
        <CustomDialog
          title="Shared Links"
          isOpen={open}
          handleDialogClose={dialogClose}
        >
          <ShareCvs
            link={shortLink.link as string}
            employees={
              shortLink.employees?.map((e) => e.givenName).join(", ") as string
            }
          />
        </CustomDialog>{" "}
        <CustomDialog
          title="Select Candidate"
          isOpen={openVisa}
          handleDialogClose={dialogCloseVisa}
        >
          <Box sx={{ p: 2, width: "400px", height: "400px" }}>
            <VisaBrief />
          </Box>
        </CustomDialog>{" "}
        {/**/}
      </Box>
    </>
  );
};
