import { createTheme, Theme } from "@mui/material/styles";
import { lightTheme, darkTheme } from "./custom";
import { typo } from "./typography";
import * as locales from "@mui/material/locale";

const OpenSans =
  process.env.REACT_APP_ENV === "development"
    ? "http://localhost:4400/fonts/open-sans-regular.ttf"
    : "https://enjazplus.com/fonts/open-sans-regular.ttf";

const overrides = {
  typography: { ...typo },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Open Sans'), local('OpenSans-Regular'), url(${OpenSans}) format('ttf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    // Name of the component ⚛️
    MuiButtonBase: {
      defaultProps: {
        // The props to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          "body[dir='rtl'] &": {
            transform: "scaleX(-1)",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "body[dir='rtl'] &": {
            right: 0,
            transformOrigin: "top right",
            transform: "translate(-14px, 9px) scale(0.75)",
          },
        },
        shrink: {
          "body[dir='rtl'] &": {
            right: 0,

            transformOrigin: "top right",
            transform: "translate(-14px, -9px) scale(0.75)",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          "body[dir='rtl'] &": {
            textAlign: "right",
          },
        },
      },
    },
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        "body[dir=rtl] &": {
          transform: "scaleX(-1)",
        },
      },
    },
    MuiInputLabel: {
      root: {
        "body[dir='rtl'] &": {
          right: 0,
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      },
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#F3F5FF !important",
          "&:focus": {
            backgroundColor: "#F3F5FF",
          },
        },
      },
      button: {
        "&:hover, &:focus": {
          backgroundColor: "#F3F5FF",
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white",
      },
    },
    // MuiTableRow: {
    //   root: {
    //     height: 56,
    //   },
    // },
    // MuiTableCell: {
    //   root: {
    //     borderBottom: "1px solid rgba(224, 224, 224, .5)",
    //     paddingLeft: 24,
    //   },
    //   head: {
    //     fontSize: "0.95rem",
    //   },
    //   body: {
    //     fontSize: "0.95rem",
    //   },
    // },
    PrivateSwitchBase: {
      root: {
        marginLeft: 10,
      },
    },
  },
};

const lang = localStorage.getItem("i18nextLng")
  ? localStorage.getItem("i18nextLng")
  : "en";
const locale = lang === "ar" ? "arSA" : lang === "et" ? "amET" : "enUS";
const themes = {
  overrides,
  light: createTheme({ ...lightTheme, ...overrides }, locales[locale]) as Theme,
  dark: createTheme({ ...darkTheme, ...overrides }, locales[locale]) as Theme,
};
// { ...customTheme, ...overrides }),

export default themes;
