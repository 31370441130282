import { Outlet } from "react-router-dom";
import { Box, experimentalStyled } from "@mui/material";
import MainNavbar from "./MainNavbar";
import { useAppSelector } from "../app/hooks";
import { selectAuth } from "../features/auth/authSlice";
import Toast from "./Layout/Toast";
import SupportChat from "./footer/SupportChat";

const MainLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const MainLayoutWrapper = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 64,
});

const MainLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const MainLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
});

const MainLayout = () => {
  const { success } = useAppSelector(selectAuth);

  return (
    <MainLayoutRoot>
      <MainNavbar />
      <Box>
        {success && <Toast severity="success">{success!.message}</Toast>}
      </Box>
      <MainLayoutWrapper>
        <MainLayoutContainer>
          <MainLayoutContent>
            <Outlet />
          </MainLayoutContent>
        </MainLayoutContainer>
        <SupportChat />
      </MainLayoutWrapper>
    </MainLayoutRoot>
  );
};

export default MainLayout;
