import { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, useTheme } from "@mui/material";
import PhoneIcon from "@mui/icons-material/LocationOn";
import HeaderIcon from "@mui/icons-material/Topic";
import { AddressDetail } from "./AddressDetail";
import { FormikProps } from "formik";
import { Visa } from "../types/processTypes";
import { VisaCondition } from "./VisaCondition";

interface VisaTabProps {
  formikData: FormikProps<Visa>;
}
export const VisaTabs = ({ formikData }: VisaTabProps) => {
  const theme = useTheme();
  const [tabValue, setValue] = useState("0");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div>
      <TabContext value={tabValue}>
        <Box
          sx={{
            my: 1,
            backgroundColor: theme.palette.primary.main,
            borderRadius: "4px",
          }}
        >
          <TabList
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="lab API tabs example"
          >
            <Tab
              icon={<PhoneIcon />}
              label="Sponsor Address"
              value="0"
              sx={{
                color: theme.palette.common.white,
                "&.Mui-selected": {
                  backgroundColor: theme.palette.common.white,
                },
              }}
            />
            <Tab
              icon={<HeaderIcon />}
              label="Visa Condition"
              value="1"
              sx={{
                color: theme.palette.common.white,
                "&.Mui-selected": {
                  backgroundColor: theme.palette.common.white,
                },
              }}
            />
          </TabList>
        </Box>
        <TabPanel value="0">
          <AddressDetail />
        </TabPanel>
        <TabPanel value="1">
          <VisaCondition formikData={formikData} />
        </TabPanel>
      </TabContext>
    </div>
  );
};
