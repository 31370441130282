import { gql } from "@apollo/client";
import { GET_USER } from "../fragments/users";

export const GET_ALL_USERS = gql`
  ${GET_USER}
  query GetUsers(
    $searchText: String
    $companyId: String
    $skip: Int!
    $take: Int!
  ) {
    Users(
      companyId: $companyId
      skip: $skip
      take: $take
      searchText: $searchText
    ) {
      totalCount
      users {
        ...getUser
      }
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query GetRoles {
    GetRoles {
      _id
      displayName
    }
  }
`;

export const GET_SELECTED_USER = gql`
  ${GET_USER}
  query GetSelectedUser($id: String) {
    GetUser(input: { _id: $id }) {
      ...getUser
    }
  }
`;
