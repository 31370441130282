import Paper from "@mui/material/Paper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  fetchEmployees,
  generateLink,
  selectProcesses,
  sharedEmployees,
} from "../processSlices";
import AuthSkeleton from "../../auth/AuthSkeleton";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import { NavLink as RouterLink, useParams } from "react-router-dom";
import {
  Table,
  TableHead,
  TableBody,
  Tooltip,
  useTheme,
  Stack,
  Box,
  MobileStepper,
  Container,
} from "@mui/material";
import {
  StyledTableRow,
  StyledTableCellLabel,
  StyledTableCellValue,
} from "../../../styles/tableStyles";
import Image from "mui-image";
import { Edit, List, Share, ThumbUp } from "@mui/icons-material";
import {
  Employee,
  EmployeeStatus,
  ShortLink,
  Skills,
} from "../types/processTypes";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
//import { autoPlay } from "react-swipeable-views-utils";
import {
  changePageTitle,
  selectPreference,
} from "../../preferences/preferencesSlice";
import { useTranslation } from "react-i18next";
import { differenceInMonths, format } from "date-fns";

//const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
import { bindKeyboard } from "react-swipeable-views-utils";
import CustomDialog from "../../../components/modals/CustomDialog";
import ShareCvs from "./ShareCvs";

const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);

function CvDetailCarousel() {
  const { link } = useParams() as {
    link: string;
  };
  const dispatch = useAppDispatch();
  const { t: tt } = useTranslation();
  const { t: te } = useTranslation("enums");
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [maxSteps, setMaxSteps] = useState(0);
  const { searchText } = useAppSelector(selectPreference);
  const [open, setOpen] = useState(false);
  const [shortLink, setShortLink] = useState<ShortLink>({});

  const {
    selectedEmployee,
    employeesWithCount: { employees },
    sharedLinks,
    selectedShortLink,
    success,
    loading,
  } = useAppSelector(selectProcesses);
  useEffect(() => {
    dispatch(changePageTitle("Cvs Detail"));
    if (!employees || employees.length === 0 || searchText) {
      if (link && link.length === 6) {
        dispatch(
          sharedEmployees({
            link: link,
          })
        );
      } else {
        dispatch(
          fetchEmployees({
            searchText: "",
            skip: 0,
            take: 50,
            status: EmployeeStatus.new,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchText, link]);

  useEffect(() => {
    setMaxSteps(employees.length);
    if (selectedEmployee.givenName === "") {
      //dispatch(setSelectedEmployee(employees[0]));
      setActiveStep(0);
    } else {
      let indexOf = employees.findIndex(
        (e: Employee) => e._id === selectedEmployee._id
      );
      setActiveStep(indexOf);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, employees]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };
  const shareSingle = (employeeId: string) => {
    dispatch(generateLink([employeeId]));
  };
  const dialogClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (success && selectedShortLink && selectedShortLink.link) {
      setOpen(true);
      setShortLink(selectedShortLink);
    }
  }, [success, selectedShortLink]);
  const shareCV = () => {};
  return (
    <>
      {employees && employees.length > 0 && (
        <Container maxWidth="lg">
          <Box>
            <Stack direction="row" justifyContent="space-between">
              {!link && (
                <Tooltip title="Show List">
                  <Button
                    color="secondary"
                    component={RouterLink}
                    variant="contained"
                    to={`/app/cvs`}
                    size="small"
                  >
                    <List />
                  </Button>
                </Tooltip>
              )}
              <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-end"
                sx={{
                  width: "100%",
                  "& > :not(style)": {
                    m: 0,
                    ml: 1,
                  },
                }}
              >
                <Tooltip title="Select Candidate">
                  <Button
                    color="secondary"
                    onClick={shareCV}
                    variant="contained"
                    size="small"
                  >
                    <ThumbUp />
                  </Button>
                </Tooltip>
                {!link && (
                  <Tooltip title="Edit Detail">
                    <Button
                      color="secondary"
                      component={RouterLink}
                      variant="contained"
                      to={`/app/employee/${
                        employees[activeStep]._id as string
                      }`}
                      size="small"
                    >
                      <Edit />
                    </Button>
                  </Tooltip>
                )}
                <Tooltip title="Share Candidate">
                  <Button
                    color="secondary"
                    onClick={() =>
                      shareSingle(employees[activeStep]._id as string)
                    }
                    variant="contained"
                    size="small"
                  >
                    <Share />
                  </Button>
                </Tooltip>
              </Stack>
            </Stack>

            <Box
              component={Paper}
              elevation={3}
              sx={{ display: "block", overflow: "hidden", width: "100%" }}
            >
              <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    {tt("next")}
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    <KeyboardArrowLeft />
                    {tt("back")}
                  </Button>
                }
              />
              <BindKeyboardSwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                disableLazyLoading
                ignoreNativeScroll
              >
                {employees.map((step, index) => (
                  <div
                    key={index}
                    dir={theme.direction === "rtl" ? "rtl" : "ltr"}
                  >
                    {Math.abs(activeStep - index) <= 2 ? (
                      <>
                        {/* <Typography>{"Status Tracking Number"}</Typography> */}
                        <Grid
                          container
                          spacing={1}
                          sx={{
                            p: 1,
                          }}
                        >
                          <Grid item md={4} xs={12}>
                            {loading === "pending" ? (
                              <AuthSkeleton />
                            ) : (
                              <Stack
                                spacing={1}
                                sx={{
                                  alignItems: "center",
                                }}
                              >
                                <Image
                                  src={
                                    step && step.facePhoto
                                      ? (step.facePhoto as string)
                                      : "/blankShort.jpg"
                                  }
                                  alt={step.passportNumber}
                                  width={180}
                                  height={180}
                                  shift="top"
                                  distance="2rem"
                                  shiftDuration={320}
                                  style={{
                                    borderRadius: "50%",
                                  }}
                                />
                                <TableContainer
                                  component={Paper}
                                  sx={{
                                    marginTop: 1,
                                    borderRadius: "10px",
                                    width: "100%",
                                  }}
                                >
                                  <Table size="small" aria-label="simple table">
                                    <TableHead>
                                      <StyledTableRow key="3">
                                        <StyledTableCellLabel
                                          colSpan={2}
                                          sx={{ textAlign: "center" }}
                                        >
                                          {step.givenName +
                                            " (" +
                                            step.passportNumber +
                                            ")"}
                                        </StyledTableCellLabel>
                                      </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                      <StyledTableRow key="1">
                                        <StyledTableCellLabel>
                                          {te("profession")}
                                        </StyledTableCellLabel>
                                        <StyledTableCellValue>
                                          {te(
                                            (
                                              step.additionalData
                                                ?.appliedProfession as string
                                            ).toLowerCase()
                                          )}
                                        </StyledTableCellValue>
                                      </StyledTableRow>
                                      <StyledTableRow key="1">
                                        <StyledTableCellLabel>
                                          {tt("salary")}
                                        </StyledTableCellLabel>
                                        <StyledTableCellValue>
                                          {step.additionalData?.salary?.toLocaleString(
                                            theme.direction === "rtl"
                                              ? "ar-SA"
                                              : "en-US"
                                          ) +
                                            " " +
                                            te(
                                              (
                                                step.additionalData
                                                  ?.currency as string
                                              ).toLowerCase()
                                            )}
                                        </StyledTableCellValue>
                                      </StyledTableRow>
                                      <StyledTableRow key="1">
                                        <StyledTableCellLabel>
                                          {te("contractPeriod")}
                                        </StyledTableCellLabel>
                                        <StyledTableCellValue>
                                          {te(
                                            step.additionalData
                                              ?.contractPeriod as string
                                          )}
                                        </StyledTableCellValue>
                                      </StyledTableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                <TableContainer
                                  component={Paper}
                                  sx={{
                                    marginTop: 1,
                                    borderRadius: "10px",
                                    width: "100%",
                                  }}
                                >
                                  <Table size="small" aria-label="simple table">
                                    <TableHead>
                                      <StyledTableRow key="3">
                                        <StyledTableCellLabel colSpan={2}>
                                          {te("language") +
                                            " " +
                                            tt("and") +
                                            te("educationLevel")}
                                        </StyledTableCellLabel>
                                      </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                      <StyledTableRow key="0">
                                        <StyledTableCellLabel>
                                          {te("arabic")}
                                        </StyledTableCellLabel>
                                        <StyledTableCellValue>
                                          {te(
                                            step.education
                                              ?.arabicLanguage as string
                                          )}
                                        </StyledTableCellValue>
                                      </StyledTableRow>
                                      <StyledTableRow key="1">
                                        <StyledTableCellLabel>
                                          {te("english")}
                                        </StyledTableCellLabel>
                                        <StyledTableCellValue>
                                          {te(
                                            step.education
                                              ?.englishLanguage as string
                                          )}
                                        </StyledTableCellValue>
                                      </StyledTableRow>
                                      <StyledTableRow key="2">
                                        <StyledTableCellLabel>
                                          {te("educationLevel")}
                                        </StyledTableCellLabel>
                                        <StyledTableCellValue>
                                          {te(
                                            step.education
                                              ?.educationLevel as string
                                          )}
                                        </StyledTableCellValue>
                                      </StyledTableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                <Stack sx={{ marginTop: 1, width: "100%" }}>
                                  {step.workExperience &&
                                  step.workExperience.length <= 0 ? (
                                    <>
                                      <TableContainer
                                        component={Paper}
                                        sx={{
                                          borderRadius: "10px",
                                          width: "100%",
                                        }}
                                      >
                                        <Table
                                          size="small"
                                          aria-label="simple table"
                                        >
                                          <TableHead>
                                            <StyledTableRow key="3">
                                              <StyledTableCellLabel colSpan={3}>
                                                {tt("workExperience")}
                                              </StyledTableCellLabel>
                                            </StyledTableRow>
                                          </TableHead>
                                          <TableBody>
                                            <StyledTableRow>
                                              <StyledTableCellValue>
                                                {tt("firstTimer")}
                                              </StyledTableCellValue>
                                            </StyledTableRow>
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </>
                                  ) : (
                                    <TableContainer
                                      component={Paper}
                                      sx={{
                                        borderRadius: "10px",
                                        width: "100%",
                                      }}
                                    >
                                      <Table
                                        size="small"
                                        aria-label="simple table"
                                      >
                                        <TableHead>
                                          <StyledTableRow key="3">
                                            <StyledTableCellLabel colSpan={3}>
                                              {tt("workExperience")}
                                            </StyledTableCellLabel>
                                          </StyledTableRow>
                                          <StyledTableRow>
                                            <StyledTableCellLabel>
                                              {te("country")}
                                            </StyledTableCellLabel>
                                            <StyledTableCellLabel>
                                              {te("profession")}
                                            </StyledTableCellLabel>
                                            <StyledTableCellLabel>
                                              {te("forYears")}
                                            </StyledTableCellLabel>
                                          </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                          {step.workExperience &&
                                            step.workExperience.map(
                                              (row, index) => (
                                                <StyledTableRow key={index}>
                                                  <StyledTableCellValue>
                                                    {te(row.country as string)}
                                                  </StyledTableCellValue>
                                                  <StyledTableCellValue>
                                                    {te(
                                                      row.profession as string
                                                    )}
                                                  </StyledTableCellValue>
                                                  <StyledTableCellValue>
                                                    {te(row.years as string)}
                                                  </StyledTableCellValue>
                                                </StyledTableRow>
                                              )
                                            )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  )}
                                </Stack>
                              </Stack>
                            )}
                          </Grid>
                          <Grid
                            item
                            md={4}
                            xs={12}
                            justifyContent="flex-start"
                            sx={{ mt: 1 }}
                          >
                            <TableContainer
                              component={Paper}
                              sx={{ borderRadius: "10px", width: "100%" }}
                            >
                              <Table size="medium" aria-label="simple table">
                                <TableHead>
                                  <StyledTableRow>
                                    <StyledTableCellLabel colSpan={2}>
                                      {tt("detailsOfApplication")}
                                    </StyledTableCellLabel>
                                  </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                  <StyledTableRow key="0">
                                    <StyledTableCellLabel>
                                      {te("nationality")}
                                    </StyledTableCellLabel>
                                    <StyledTableCellValue>
                                      {te(
                                        step.additionalData
                                          ?.nationality as string
                                      )}
                                    </StyledTableCellValue>
                                  </StyledTableRow>
                                  <StyledTableRow key="1">
                                    <StyledTableCellLabel>
                                      {te("religion")}
                                    </StyledTableCellLabel>
                                    <StyledTableCellValue>
                                      {te(
                                        step.additionalData?.religion as string
                                      )}
                                    </StyledTableCellValue>
                                  </StyledTableRow>
                                  <StyledTableRow key="2">
                                    <StyledTableCellLabel>
                                      {tt("dateOfBirth")}
                                    </StyledTableCellLabel>
                                    <StyledTableCellValue>
                                      {theme.direction === "ltr"
                                        ? format(
                                            new Date(step.dateOfBirth as Date),
                                            "dd-MMM-yyyy"
                                          )
                                        : new Date(
                                            step.dateOfBirth as Date
                                          ).toLocaleDateString("ar-SA", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                          })}
                                    </StyledTableCellValue>
                                  </StyledTableRow>
                                  <StyledTableRow key="3">
                                    <StyledTableCellLabel>
                                      {tt("age")}
                                    </StyledTableCellLabel>
                                    <StyledTableCellValue>
                                      {Math.floor(
                                        differenceInMonths(
                                          new Date(),
                                          new Date(step.dateOfBirth as Date)
                                        ) / 12
                                      ).toLocaleString(
                                        theme.direction === "rtl"
                                          ? "ar-SA"
                                          : "en-US"
                                      )}
                                    </StyledTableCellValue>
                                  </StyledTableRow>

                                  <StyledTableRow key="5">
                                    <StyledTableCellLabel>
                                      {te("maritalStatus")}
                                    </StyledTableCellLabel>
                                    <StyledTableCellValue>
                                      {te(
                                        step.additionalData
                                          ?.maritalStatus as string
                                      )}
                                    </StyledTableCellValue>
                                  </StyledTableRow>
                                  <StyledTableRow key="6">
                                    <StyledTableCellLabel>
                                      {tt("numberOfChildren")}
                                    </StyledTableCellLabel>
                                    <StyledTableCellValue>
                                      {step.additionalData?.numberOfChildren?.toLocaleString(
                                        theme.direction === "rtl"
                                          ? "ar-SA"
                                          : "en-US"
                                      )}
                                    </StyledTableCellValue>
                                  </StyledTableRow>
                                  <StyledTableRow key="7">
                                    <StyledTableCellLabel>
                                      {tt("weight") + "/" + tt("height")}
                                    </StyledTableCellLabel>
                                    <StyledTableCellValue>
                                      {(step.additionalData?.weight === 0
                                        ? "-"
                                        : step.additionalData?.weight?.toLocaleString(
                                            theme.direction === "rtl"
                                              ? "ar-SA"
                                              : "en-US"
                                          ) + tt("kg")) +
                                        "/" +
                                        (step.additionalData?.height === 0
                                          ? "-"
                                          : step.additionalData?.height?.toLocaleString(
                                              theme.direction === "rtl"
                                                ? "ar-SA"
                                                : "en-US"
                                            ) + tt("meter"))}
                                    </StyledTableCellValue>
                                  </StyledTableRow>

                                  <StyledTableRow key="9">
                                    <StyledTableCellLabel>
                                      {te("complexion")}
                                    </StyledTableCellLabel>
                                    <StyledTableCellValue>
                                      {te(
                                        step.additionalData
                                          ?.complexion as string
                                      )}
                                    </StyledTableCellValue>
                                  </StyledTableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                            {step.education && step.education.skills && (
                              <TableContainer
                                component={Paper}
                                sx={{
                                  marginTop: 1,
                                  borderRadius: "10px",
                                  width: "100%",
                                }}
                              >
                                <Table size="small" aria-label="simple table">
                                  <TableHead>
                                    <StyledTableRow key="3">
                                      <StyledTableCellLabel colSpan={4}>
                                        {te("skills")}
                                      </StyledTableCellLabel>
                                    </StyledTableRow>
                                  </TableHead>
                                  <TableBody>
                                    <StyledTableRow key="0">
                                      <StyledTableCellLabel
                                        sx={{
                                          backgroundColor:
                                            theme.palette.primary.main,
                                          color: "white",
                                        }}
                                      >
                                        {te("washing_dishes")}
                                      </StyledTableCellLabel>
                                      <StyledTableCellValue>
                                        {tt("yes")}
                                      </StyledTableCellValue>
                                      <StyledTableCellLabel
                                        sx={{
                                          backgroundColor:
                                            theme.palette.primary.main,
                                          color: "white",
                                        }}
                                      >
                                        {te("washing_ironing_clothes")}
                                      </StyledTableCellLabel>
                                      <StyledTableCellValue>
                                        {step.education?.skills?.indexOf(
                                          Skills.washing_ironing_clothes
                                        ) > -1
                                          ? tt("yes")
                                          : tt("no")}
                                      </StyledTableCellValue>
                                    </StyledTableRow>
                                    <StyledTableRow key="1">
                                      <StyledTableCellLabel
                                        sx={{
                                          backgroundColor:
                                            theme.palette.primary.main,
                                          color: "white",
                                        }}
                                      >
                                        {te("dusting_cleaning")}
                                      </StyledTableCellLabel>
                                      <StyledTableCellValue>
                                        {step.education?.skills?.indexOf(
                                          Skills.dusting_cleaning
                                        ) > -1
                                          ? tt("yes")
                                          : tt("no")}
                                      </StyledTableCellValue>
                                      <StyledTableCellLabel
                                        sx={{
                                          backgroundColor:
                                            theme.palette.primary.main,
                                          color: "white",
                                        }}
                                      >
                                        {te("driving")}
                                      </StyledTableCellLabel>
                                      <StyledTableCellValue>
                                        {step.education?.skills?.indexOf(
                                          Skills.driving
                                        ) > -1
                                          ? tt("yes")
                                          : tt("no")}
                                      </StyledTableCellValue>
                                    </StyledTableRow>

                                    <StyledTableRow key="3">
                                      <StyledTableCellLabel
                                        sx={{
                                          backgroundColor:
                                            theme.palette.primary.main,
                                          color: "white",
                                        }}
                                      >
                                        {te("baby_sitting")}
                                      </StyledTableCellLabel>
                                      <StyledTableCellValue>
                                        {step.education?.skills?.indexOf(
                                          Skills.baby_sitting
                                        ) > -1
                                          ? tt("yes")
                                          : tt("no")}
                                      </StyledTableCellValue>
                                      <StyledTableCellLabel
                                        sx={{
                                          backgroundColor:
                                            theme.palette.primary.main,
                                          color: "white",
                                        }}
                                      >
                                        {te("children_care")}
                                      </StyledTableCellLabel>
                                      <StyledTableCellValue>
                                        {step.education?.skills?.indexOf(
                                          Skills.children_care
                                        ) > -1
                                          ? tt("yes")
                                          : tt("no")}
                                      </StyledTableCellValue>
                                    </StyledTableRow>
                                    <StyledTableRow key="4">
                                      <StyledTableCellLabel
                                        sx={{
                                          backgroundColor:
                                            theme.palette.primary.main,
                                          color: "white",
                                        }}
                                      >
                                        {te("cooking")}
                                      </StyledTableCellLabel>
                                      <StyledTableCellValue>
                                        {step.education?.skills?.indexOf(
                                          Skills.cooking
                                        ) > -1
                                          ? tt("yes")
                                          : tt("no")}
                                      </StyledTableCellValue>
                                      <StyledTableCellLabel
                                        sx={{
                                          backgroundColor:
                                            theme.palette.primary.main,
                                          color: "white",
                                        }}
                                      >
                                        {te("arabic_cooking")}
                                      </StyledTableCellLabel>
                                      <StyledTableCellValue>
                                        {step.education?.skills?.indexOf(
                                          Skills.arabic_cooking
                                        ) > -1
                                          ? tt("yes")
                                          : tt("no")}
                                      </StyledTableCellValue>
                                    </StyledTableRow>
                                    <StyledTableRow key="5">
                                      <StyledTableCellLabel
                                        sx={{
                                          backgroundColor:
                                            theme.palette.primary.main,
                                          color: "white",
                                        }}
                                      >
                                        {te("tutoring_children")}
                                      </StyledTableCellLabel>
                                      <StyledTableCellValue>
                                        {step.education?.skills?.indexOf(
                                          Skills.tutoring_children
                                        ) > -1
                                          ? tt("yes")
                                          : tt("no")}
                                      </StyledTableCellValue>
                                      <StyledTableCellLabel
                                        sx={{
                                          backgroundColor:
                                            theme.palette.primary.main,
                                          color: "white",
                                        }}
                                      >
                                        {te("sewing")}
                                      </StyledTableCellLabel>
                                      <StyledTableCellValue>
                                        {step.education?.skills?.indexOf(
                                          Skills.sewing
                                        ) > -1
                                          ? tt("yes")
                                          : tt("no")}
                                      </StyledTableCellValue>
                                    </StyledTableRow>
                                    <StyledTableRow key="6">
                                      <StyledTableCellLabel
                                        sx={{
                                          backgroundColor:
                                            theme.palette.primary.main,
                                          color: "white",
                                        }}
                                      >
                                        {te("computer")}
                                      </StyledTableCellLabel>
                                      <StyledTableCellValue>
                                        {step.education?.skills?.indexOf(
                                          Skills.computer
                                        ) > -1
                                          ? tt("yes")
                                          : tt("no")}
                                      </StyledTableCellValue>
                                      <StyledTableCellLabel
                                        sx={{
                                          backgroundColor:
                                            theme.palette.primary.main,
                                          color: "white",
                                        }}
                                      >
                                        {te("decorating")}
                                      </StyledTableCellLabel>
                                      <StyledTableCellValue>
                                        {step.education?.skills?.indexOf(
                                          Skills.decorating
                                        ) > -1
                                          ? tt("yes")
                                          : tt("no")}
                                      </StyledTableCellValue>
                                    </StyledTableRow>

                                    <StyledTableRow key="7">
                                      <StyledTableCellLabel
                                        sx={{
                                          backgroundColor:
                                            theme.palette.primary.main,
                                          color: "white",
                                        }}
                                      >
                                        {te("other_skills")}
                                      </StyledTableCellLabel>
                                      <StyledTableCellValue
                                        colSpan={3}
                                      ></StyledTableCellValue>
                                    </StyledTableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            )}
                          </Grid>

                          <Grid
                            item
                            md={4}
                            xs={12}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            {loading === "pending" ? (
                              <AuthSkeleton />
                            ) : (
                              <Stack
                                spacing={1}
                                sx={{
                                  alignItems: "center",
                                }}
                              >
                                {/* <TableContainer
                                  component={Paper}
                                  sx={{
                                    marginTop: 1,
                                    borderRadius: "10px",
                                    width: "100%",
                                  }}
                                >
                                  <Table size="small" aria-label="simple table">
                                    <TableHead>
                                      <StyledTableRow key="3">
                                        <StyledTableCellLabel
                                          colSpan={2}
                                          sx={{ textAlign: "center" }}
                                        >
                                          {"Tracking Code " +
                                            step._id?.toString().substring(18) +
                                            ""}
                                        </StyledTableCellLabel>
                                      </StyledTableRow>
                                    </TableHead>
                                  </Table>
                                </TableContainer> */}
                                <Image
                                  src={
                                    step && step.standPhoto
                                      ? (step.standPhoto as string)
                                      : "/blankLong.jpg"
                                  }
                                  alt={step.passportNumber}
                                  width={220}
                                  height={550}
                                  shift="top"
                                  distance="2rem"
                                  shiftDuration={320}
                                />
                              </Stack>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <TableContainer
                              component={Paper}
                              sx={{
                                marginTop: 1,
                                borderRadius: "10px",
                                width: "100%",
                              }}
                            >
                              <Table size="small" aria-label="simple table">
                                <TableBody>
                                  <StyledTableRow key="0">
                                    <StyledTableCellLabel
                                      sx={{
                                        backgroundColor:
                                          theme.palette.primary.main,
                                        color: "white",
                                        width: "20%",
                                      }}
                                    >
                                      {tt("remark")}
                                    </StyledTableCellLabel>
                                    <StyledTableCellValue sx={{ width: "80%" }}>
                                      {step.additionalData?.notes as string}
                                    </StyledTableCellValue>
                                  </StyledTableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                  </div>
                ))}
              </BindKeyboardSwipeableViews>
            </Box>
          </Box>
        </Container>
      )}
      <CustomDialog
        title="Shared Links"
        isOpen={open}
        handleDialogClose={dialogClose}
      >
        <ShareCvs
          link={shortLink.link as string}
          employees={
            shortLink.employees?.map((e) => e.givenName).join(", ") as string
          }
        />
      </CustomDialog>{" "}
    </>
  );
}

export default CvDetailCarousel;
