import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchEmployees,
  selectProcesses,
  removeEmployee,
} from "../process/processSlices";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import { NavLink as RouterLink } from "react-router-dom";

import {
  changePageTitle,
  selectPreference,
} from "../preferences/preferencesSlice";
//import Avatar from "@mui/material/Avatar";
import {
  IconButton,
  Stack,
  Button,
  Typography,
  Tooltip,
  Box,
  Avatar,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";

import { StyledTableCell, StyledTableRow } from "../../styles/tableStyles";
import TableSkeleton from "../../components/Layout/TableSkeleton";
import Paging from "../../components/Layout/Paging";

export const Employees = () => {
  const dispatch = useAppDispatch();
  const {
    employeesWithCount: { employees, totalCount },
    loading,
  } = useAppSelector(selectProcesses);
  //const { Employee } = useAppSelector(selectAuth);
  const { searchText } = useAppSelector(selectPreference);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    dispatch(changePageTitle("Employees List"));

    const skipRows = currentPage * rowsPerPage;

    dispatch(
      fetchEmployees({
        searchText,
        skip: skipRows,
        take: rowsPerPage,
      })
    );
  }, [dispatch, searchText, currentPage, rowsPerPage]); //

  useEffect(() => {
    setTotal(totalCount as number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount]);

  const DeleteEmployee = (id: string) => {
    dispatch(removeEmployee(id));
  };

  const RefreshList = () => {
    const skipRows = currentPage * rowsPerPage;
    dispatch(
      fetchEmployees({
        refreshList: "refresh",
        searchText,
        skip: skipRows,
        take: rowsPerPage,
      })
    );
  };
  return (
    <>
      <Helmet>
        <title>Employees | Enjaz+</title>
      </Helmet>
      <Box
        sx={{
          m: { xs: 0, md: 1 },
          px: { xs: 0, md: 2 },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          justifyItems="center"
        >
          <Tooltip title="Add New Item">
            <Button
              color="secondary"
              variant="contained"
              component={RouterLink}
              to={`/app/employee/0`}
            >
              <Add />
            </Button>
          </Tooltip>
        </Stack>

        <Grid container justifyContent="flex-start" sx={{ mt: 1 }}>
          <TableContainer component={Paper}>
            <Table size="medium" aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>S.No</StyledTableCell>
                  <StyledTableCell>Photo</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Passport No.</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {loading === "pending" ? (
                  <TableSkeleton numRows={5} numColumns={5} />
                ) : (
                  employees.map((row, index) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell component="th" scope="row">
                        {currentPage * rowsPerPage + index + 1}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Avatar
                          component={RouterLink}
                          alt="avatar"
                          src={row ? row.facePhoto : ""}
                          sx={{
                            cursor: "pointer",
                            height: 40,
                            width: 40,
                          }}
                          to={`/app/employee/${row._id}`}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          color="primary"
                          component={RouterLink}
                          to={`/app/employee/${row._id}`}
                          size="small"
                        >
                          {row.givenName + " " + row.surName}
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          color="primary"
                          component={RouterLink}
                          to={`/app/employee/${row._id}`}
                          size="small"
                        >
                          {row.passportNumber}
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>{row.status}</StyledTableCell>

                      <StyledTableCell>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <IconButton
                            color="primary"
                            component={RouterLink}
                            to={"/app/employee/" + row._id}
                            size="large"
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            color="secondary"
                            onClick={() => DeleteEmployee(row._id as string)}
                            size="large"
                          >
                            <Delete />
                          </IconButton>
                        </Stack>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Stack spacing={1}>
          <Paging
            total={total}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            setRowsPerPage={setRowsPerPage}
            setCurrentPage={setCurrentPage}
          />
          <Typography variant="body2" color="textPrimary" component="div">
            Number of Employees: {total}
          </Typography>
        </Stack>
      </Box>
    </>
  );
};
