import { ChangeEvent, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { deleteFile } from "../../auth/authSlice";
import Delete from "@mui/icons-material/Delete";
import AddAPhoto from "@mui/icons-material/AddAPhoto";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import Badge from "@mui/material/Badge";
import Paper from "@mui/material/Paper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Box from "@mui/material/Box";
import Toast from "../../../components/Layout/Toast";
import { PhotoTypes } from "../types/processTypes";
import { selectProcesses, uploadPicture } from "../processSlices";
import AuthSkeleton from "../../auth/AuthSkeleton";
import { resizeFile } from "../../../utils/imageUtility";

function StandPhoto() {
  const [image, setImage] = useState("");

  const {
    selectedEmployee,
    success,
    loading: loadingStandPhoto,
    error,
  } = useAppSelector(selectProcesses);
  const dispatch = useAppDispatch();
  const onChangeStand = async (event: ChangeEvent<HTMLInputElement>) => {
    const { validity, files } = event.target;

    if (validity.valid && files && files[0]) {
      const image = (await resizeFile(files[0])) as File;
      if (image) {
        dispatch(
          uploadPicture({
            _id: selectedEmployee!._id as string,
            type: PhotoTypes.Stand,
            avatar: image,
          })
        );
      }
    }
  };

  const removeImage = () => {
    setImage("");
    //let prof = selectedEmployee;
    //prof = { ...prof, avatar: "" } as Employee;
    //dispatch(updateProfile(prof));
    dispatch(deleteFile(selectedEmployee?.standPhoto as string));
  };

  return (
    <>
      <Paper
        elevation={0}
        style={{
          position: "relative",
          borderRadius: 18,
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <input
          name="standImage"
          accept="image/*"
          onChange={onChangeStand}
          style={{ display: "none" }}
          id="icon-button-file-stand"
          type="file"
        />
        <label htmlFor="icon-button-file-stand">
          <>
            <Badge
              overlap="rectangular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              badgeContent={
                <Tooltip
                  title="Add/Change Photo"
                  aria-label="icon-button-file-stand"
                >
                  <Fab component="span" color="primary">
                    <IconButton
                      aria-label="icon-button-file-stand"
                      component="span"
                      size="large"
                      color="inherit"
                    >
                      <AddAPhoto />
                    </IconButton>
                  </Fab>
                </Tooltip>
              }
            >
              {loadingStandPhoto === "pending" ? (
                <AuthSkeleton />
              ) : (
                <Avatar
                  alt="avatar"
                  variant="rounded"
                  src={
                    image
                      ? image
                      : selectedEmployee
                      ? selectedEmployee.standPhoto
                      : ""
                  }
                  sx={{
                    height: 500,
                    width: 250,
                  }}
                />
              )}
            </Badge>
          </>
        </label>{" "}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: 0,
          }}
        >
          {(image || (selectedEmployee && selectedEmployee.standPhoto)) && (
            <>
              <Box component="div">
                <Tooltip
                  title="Remove Photo"
                  aria-label="icon-button-file-stand"
                >
                  <Fab component="span" color="primary">
                    <IconButton
                      color="inherit"
                      edge="start"
                      onClick={removeImage}
                      size="large"
                      sx={{
                        margin: "auto",
                        width: "auto",
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Fab>
                </Tooltip>
              </Box>
            </>
          )}
        </div>
        <Box m={1}>
          {error && <Toast severity="error">{error.message}</Toast>}
          {success && <Toast severity="success">{success.message}</Toast>}
        </Box>
      </Paper>
    </>
  );
}

export default StandPhoto;
