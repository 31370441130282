import Box from "@mui/material/Box";
import { StyledLink } from "../../styles/componentStyled";
import { Tooltip, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
const SupportChat = () => {
  const theme = useTheme();
  const { t: tt } = useTranslation();
  return (
    <Box
      sx={{
        position: "fixed",
        left: theme.direction === "rtl" ? "10px" : "auto",
        right: theme.direction === "ltr" ? "10px" : "auto",
        bottom: "5px",
        zIndex: "999",
      }}
    >
      <Box
        component="span"
        sx={{
          display: "block",
          textAlign: "center",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          background: "#075E54",
          cursor: "pointer",
          border: "0.17rem solid #fff",
        }}
      >
        <Tooltip title={tt("support")}>
          <>
            <StyledLink
              aria-label="Chat on WhatsApp"
              href="https://wa.me/251911850908"
              target={"_blank"}
              sx={{
                padding: "3px",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <img
                alt="Chat on WhatsApp"
                src="/Digital_Glyph_White.svg"
                width="40px"
                height="30px"
              />
            </StyledLink>{" "}
          </>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default SupportChat;
