import { Document, Page, StyleSheet, Font } from "@react-pdf/renderer";
import { Employee } from "../../../features/process/types/processTypes";
import { BarcodeHeader } from "./BarcodeHeader";
import ListRow from "./ListRow";
import { AuthUser } from "../../../features/auth/types/authType";

Font.register({
  family: "Noto",
  format: "truetype",
  fonts: [
    {
      src: "/fonts/Noto_Naskh_Arabic/NotoNaskhArabic-VariableFont_wght.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Regular.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Medium.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Bold.ttf",
      fontWeight: 600,
    },
  ],
});
Font.register({
  family: "Open Sans",
  format: "truetype",
  fonts: [
    {
      // src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
      src: "/fonts/open-sans-regular.ttf",
    },
    {
      // src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      src: "/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
interface Props {
  employee: Employee;
  user: AuthUser;
}

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 0,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: "auto",
    marginRight: "auto",
  },
});
export const SaudiApplication = ({ employee, user }: Props) => {
  return (
    <Document
      creator="pinnasofts"
      author="pinnaSofts"
      title="enjaz+"
      subject="Embassy Application"
    >
      <Page size="A4" style={styles.page}>
        {employee &&
          employee.visa &&
          employee.passportNumber &&
          employee.passportNumber.length > 0 &&
          employee.embassyProcess &&
          employee.embassyProcess.enjazNumber && (
            <>
              <BarcodeHeader employee={employee} user={user} />
              <ListRow employee={employee} />
            </>
          )}
      </Page>
    </Document>
  );
};
