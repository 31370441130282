import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink as RouterLink, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import { StyledAccordionSummary } from "../../styles/componentStyled";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import {
  Button,
  Divider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, Backspace, Save } from "@mui/icons-material";
import Toast from "../../components/Layout/Toast";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  createVisa,
  getVisa,
  resetSelectedVisa,
  selectProcesses,
} from "./processSlices";
import { Visa } from "./types/processTypes";
import { changePageTitle } from "../preferences/preferencesSlice";
import { FormikTextField } from "../../components/Layout/FormikTextField";
import { Form, Formik, FormikProps } from "formik";
import { createVisaSchema } from "./validation";
import { VisaTabs } from "./components/VisaTabs";
import { useTranslation } from "react-i18next";
import { CompanyFilter } from "../../components/filter/CompanyFilter";
import { ClientTypes } from "../auth/types/authType";
import { Company } from "../setups/types/setupTypes";
import { selectAuth } from "../auth/authSlice";
////import Barcode, { Renderer } from "react-jsbarcode";
// import JsBarcode from "jsbarcode";
// import QRCode from "qrcode";
//import { Page, Text, View, Document } from "@react-pdf/renderer";
// import ReportViewer from "../../components/report/ReportViewer";
// const canvas = document.createElement("canvas");
// const qrCanvas = document.createElement("canvas");
// const styles = StyleSheet.create({
//   image: {
//     width: 150,
//     height: 40,
//   },
// });
// const MyDoc = (
//   <Document
//     creator="pinnasofts"
//     author="pinnaSofts"
//     title="enjaz+"
//     subject="embassy Application"
//   >
//     <Page>
//       <View>
//         <Text>New barcode</Text>
//         <Text>End new barcode</Text>
//       </View>
//     </Page>
//   </Document>
// );
export const VisaEntry = () => {
  const { id } = useParams() as {
    id: string;
  };
  const { t } = useTranslation();
  //const location = useLocation();
  ////let blobImage: Blob;

  // JsBarcode(canvas, "1452369856", {
  //   format: "code128a",
  //   width: 1,
  //   height: 40,
  //   textPosition: "bottom",
  //   fontSize: 14,
  // });
  //const barcode = canvas.toDataURL();
  //// canvas.toBlob((blob) => {
  ////   blobImage = blob as Blob;
  //// });
  //QRCode.toCanvas(qrCanvas, location.pathname); //window.location.href
  //const qr = qrCanvas.toDataURL("png");
  const [company, setCompany] = useState<Company>();

  const { selectedVisa, error, success } = useAppSelector(selectProcesses);

  //const [instance, updateInstance] = usePDF({ document: MyDoc });
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectAuth);
  useEffect(() => {
    dispatch(changePageTitle(`Visa Entry`));

    if (id && id !== "0") {
      dispatch(getVisa(id));
    } else {
      resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  function resetFields() {
    dispatch(resetSelectedVisa());
  }
  useEffect(() => {
    if (selectedVisa && selectedVisa.owner) {
      setCompany(selectedVisa.owner);
    }
  }, [selectedVisa]);
  // function printPdf() {
  //   //console.log(instance);
  //   if (instance && instance.url) {
  //     const iframe = document.createElement("iframe"); //load content in an iframe to print later
  //     document.body.appendChild(iframe);

  //     iframe.style.display = "none";
  //     iframe.src = instance.url as string;
  //     iframe.onload = function () {
  //       setTimeout(function () {
  //         iframe.focus();
  //         iframe.contentWindow?.print();
  //       }, 1);
  //     };
  //   }
  // }

  return (
    <>
      <Helmet>
        <title> {`${selectedVisa?.fullName?.toUpperCase()} | Enjaz+`}</title>
      </Helmet>
      <Box
        sx={{
          m: { xs: 0, md: 1 },
          px: { xs: 0, md: 2 },
        }}
      >
        {/* <ReportViewer title="Embassy Application">
          <View>
            <Image style={styles.image} source={barcode} />
            <Text>New barcode</Text>
            <Text>End new barcode</Text>
          </View>
        </ReportViewer> */}
        {/* <Button color="secondary" variant="contained" onClick={printPdf}>
            <Print />
            </Button>
            {selectedVisa && selectedVisa.visaNumber && (
              <img src={barcode} alt="barcode" />
              )}
            <img src={qr} alt="qr_code" /> */}
        <>
          <Formik
            enableReinitialize={true}
            initialValues={selectedVisa as Visa}
            validationSchema={createVisaSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              //console.log(company);

              if (company) {
                values = {
                  ...values,
                  owner: company,
                };
              } else if (user && user.company) {
                values = {
                  ...values,
                  owner: user.company,
                };
              }
              dispatch(createVisa(values));
            }}
          >
            {(props: FormikProps<Visa>) => (
              <Form>
                <Stack direction="row" justifyContent="space-between">
                  <Tooltip title="Back to Visa list">
                    <Button
                      color="secondary"
                      variant="contained"
                      component={RouterLink}
                      to={"/app/visas"}
                    >
                      <Backspace />
                    </Button>
                  </Tooltip>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignSelf="flex-end"
                    sx={{
                      "& > :not(style)": {
                        m: 0,
                        ml: 1,
                      },
                    }}
                  >
                    <Tooltip
                      title={t("save") + " " + t("visa") + " " + t("data")}
                    >
                      <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        disabled={!props.isValid}
                      >
                        <Save />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      title={t("addNew") + " " + t("visa") + " " + t("data")}
                    >
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={resetFields}
                      >
                        <Add />{" "}
                      </Button>
                    </Tooltip>
                  </Stack>
                </Stack>
                <Divider variant="middle" sx={{ mt: 2 }} />
                <Accordion sx={{ my: 1 }} expanded>
                  <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h5" component="div">
                      Visa Info
                    </Typography>
                  </StyledAccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3}>
                      {user &&
                        user.company &&
                        user.company.type === ClientTypes.LocalAgency && (
                          <>
                            <Grid item xs={6}>
                              <TextField
                                variant="outlined"
                                defaultValue="Foreign Agent"
                                label="Foreign Agent"
                                value={selectedVisa.owner?.displayName}
                                disabled
                                size="small"
                                fullWidth
                                sx={{ mt: 1 }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <CompanyFilter
                                companyType={ClientTypes.ForeignAgent}
                                setCompany={setCompany}
                                companyId={
                                  selectedVisa
                                    ? selectedVisa.owner?._id
                                    : undefined
                                }
                              />
                            </Grid>
                          </>
                        )}
                      <Grid item md={4} xs={12}>
                        <FormikTextField
                          formikKey="visaNumber"
                          label="Visa Number"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <FormikTextField
                          formikKey="fullName"
                          label="Sponsor Full Name"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <FormikTextField
                          formikKey="passportNumber"
                          label="Sponsor Id"
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <VisaTabs formikData={props} />
                <br />
                {success && <Toast severity="success">{success.message}</Toast>}
                {error && <Toast severity="error">{error.message}</Toast>}
              </Form>
            )}
          </Formik>
          {error && <Toast severity="error">{error.message}</Toast>}
        </>
      </Box>
    </>
  );
};
