import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormikTextField } from "../../../components/Layout/FormikTextField";
import {
  MaritalStatus,
  ReligionTypes,
  ProfessionalTypes,
  CountriesList,
  ExperienceYears,
  Complexion,
  EducationLevel,
  Nationalities,
  CurrencyTypes,
  Employee,
} from "../types/processTypes";
import StandPhoto from "./StandPhoto";
import { useEffect, useState } from "react";
import { Company } from "../../setups/types/setupTypes";
import { selectAuth } from "../../auth/authSlice";
import { useAppSelector } from "../../../app/hooks";
import { FormikProps } from "formik";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
interface EmployeeMoreTabProps {
  formikData: FormikProps<Employee>;
}
export const EmployeeMore = ({ formikData }: EmployeeMoreTabProps) => {
  const { t: te } = useTranslation("enums");
  const { t } = useTranslation();
  const theme = useTheme();

  const [companies, setCompanies] = useState<Company[]>([]);
  //const [selectedCompanies, setSelectedCompanies] = useState<Company[]>([]);
  const { user } = useAppSelector(selectAuth);

  const [selected, setSelected] = useState<string[]>([]);
  const isAllSelected =
    companies.length > 0 && selected.length === companies.length;

  const handleChange = (value: string[]) => {
    if (value[value.length - 1] === "all") {
      setSelected(
        selected.length === companies.length
          ? []
          : companies.map((c) => c.displayName)
      );
      formikData.setFieldValue("prospects", companies);
      return;
    }
    setSelected(value as string[]);
    const filteredCompanies = companies.filter((c) =>
      value.includes(c.displayName)
    );
    formikData.setFieldValue("prospects", filteredCompanies);
  };
  useEffect(() => {
    if (formikData && formikData.values && formikData.values.prospects) {
      setSelected(formikData.values.prospects.map((f) => f.displayName));
    }
  }, [formikData]);
  useEffect(() => {
    if (user && user.company && user.company.partners) {
      setCompanies(user.company.partners);
    }
  }, [user]);
  return (
    <Grid container spacing={2}>
      <Grid container item spacing={1} md={8} xs={12}>
        <Grid item xs={12}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="multiple-select-label">CV Visible To</InputLabel>
            <Select
              labelId="multiple-select-label"
              id="prospect"
              name="prospect"
              label="CV Visible To"
              multiple
              fullWidth
              size="small"
              sx={{ mt: 1 }}
              value={selected}
              onChange={(event) => handleChange(event.target.value as string[])}
              renderValue={(selected) =>
                selected.length > 1 ? selected.join(", ") : selected
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                  },
                },

                variant: "menu",
              }}
            >
              <MenuItem
                value="all"
                sx={{
                  fontWeight: 500,
                  backgroundColor: isAllSelected
                    ? theme.palette.action.selected
                    : "inherit",
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    color="secondary"
                    classes={{
                      indeterminate: "#f50057",
                    }}
                    checked={isAllSelected}
                    indeterminate={
                      selected.length > 0 && selected.length < companies.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Select All" />
              </MenuItem>
              {companies.map((option) => (
                <MenuItem
                  key={option._id}
                  value={option.displayName}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: theme.palette.action.selected,
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      color="secondary"
                      checked={
                        selected.indexOf(option.displayName as string) > -1
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary={option.displayName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormikTextField
            formikKey="additionalData.nationality"
            select
            label={te("nationality")}
          >
            {Object.keys(Nationalities).map((option) => (
              <MenuItem key={option} value={option as string}>
                {te(option)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>

        <Grid item md={6} xs={12}>
          <FormikTextField
            formikKey="additionalData.pastNationality"
            select
            label={te("previousNationality")}
          >
            {Object.keys(Nationalities).map((option) => (
              <MenuItem key={option} value={option as string}>
                {te(option)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormikTextField
            formikKey="additionalData.maritalStatus"
            select
            label={te("maritalStatus")}
          >
            {Object.keys(MaritalStatus).map((option) => (
              <MenuItem key={option} value={option as string}>
                {te(option)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>

        <Grid item md={6} xs={12}>
          <FormikTextField
            formikKey="additionalData.religion"
            select
            label={te("religion")}
          >
            {Object.keys(ReligionTypes).map((option) => (
              <MenuItem key={option} value={option as string}>
                {te(option)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormikTextField
            formikKey="education.educationLevel"
            select
            label={te("educationLevel")}
          >
            {Object.keys(EducationLevel).map((option) => (
              <MenuItem key={option} value={option as string}>
                {te(option)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormikTextField
            formikKey="additionalData.appliedProfession"
            select
            label={te("applied") + " " + te("profession")}
          >
            {Object.keys(ProfessionalTypes).map((option) => (
              <MenuItem key={option} value={option as string}>
                {te(option)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>

        <Grid item md={6} xs={12}>
          <FormikTextField
            formikKey="additionalData.appliedCountry"
            select
            label={te("applied") + " " + te("country")}
          >
            {Object.keys(CountriesList).map((option) => (
              <MenuItem key={option} value={option as string}>
                {te(option)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormikTextField
            formikKey="additionalData.contractPeriod"
            select
            label={te("contractPeriod")}
          >
            {Object.keys(ExperienceYears).map((option) => (
              <MenuItem key={option} value={option as string}>
                {te(option)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>

        <Grid item md={6} xs={12}>
          <FormikTextField
            formikKey="additionalData.complexion"
            select
            label={te("complexion")}
          >
            {Object.keys(Complexion).map((option) => (
              <MenuItem key={option} value={option as string}>
                {te(option)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
        <Grid item md={6} xs={12}>
          <Stack direction="row">
            <FormikTextField
              formikKey="additionalData.salary"
              label={t("salary")}
              type="number"
              sx={{ mr: 0.3, mt: 1 }}
            />
            <FormikTextField
              formikKey="additionalData.currency"
              select
              label={te("currency")}
            >
              {Object.keys(CurrencyTypes).map((option) => (
                <MenuItem key={option} value={option as string}>
                  {te(option)}
                </MenuItem>
              ))}
            </FormikTextField>
          </Stack>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormikTextField
            formikKey="additionalData.numberOfChildren"
            type="number"
            label={t("numberOfChildren")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormikTextField
            formikKey="additionalData.height"
            type="number"
            label={t("height")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormikTextField
            formikKey="additionalData.weight"
            type="number"
            label={t("weight")}
          />
        </Grid>
      </Grid>
      <Grid item md={4} xs={12}>
        <StandPhoto />
      </Grid>
    </Grid>
  );
};
